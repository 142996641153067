const urlPrefix = "https://www.meshproper.com";

const pmcPageOrigin = "https://www.ncbi.nlm.nih.gov/pmc";
const pubmedPageOrigin = "https://pubmed.ncbi.nlm.nih.gov/";
// window.localStorage的key
// 也是上发到服务器的URL parameter key
// 隐藏该显式的字符串：改为function获取字母数组和坐标数组来生成。
// 但是，通过浏览器自身，F12键-Applicaiton，能够直接看到window.localStorage，
// 比如"suffixMeSH" --- "1-DY!7PIGBG!8KFLUCHXBKZ*1-F8C6CLAT2!NVQQ-ANX9RCV8+SO#KY#7!"，
// 所以该"suffixMeSH"是否隐藏，意义不大。
const suffixMeSHJs = "suffixMeSH";
// 引入NoStrId后，区分/隔离每个userId的NoStrId，浏览器端需要userAccount字符串拼接suffixMeSHJs key。
// 每次上行，也必须提交userAccount字符串验证上行singleSessionIdStr与userId/userAccount是正确对应的。
const crtUAC = "crtUAC";
const crtUACa = "crtUACa";// crtUAC，并且是跨域req_auto_login用例。
const crtUACaNE = "crtUACan";// crtUAC，并且是跨域req_auto_login用例：不存在"crtUAC"。
// const uacaIdStrKey = "uacaStr";// 跨域req_auto_login用例，URL上行sessionIdStr的参数name。
const reqAutoPubMeSH = "ralpm";// 来自PubMed网页window.open(URL)中的URL参数名：request auto login from PubMed to MeSH Proper。

const suffi = "suffi";

// 架构层面的决策：
// 来自于网页端和插件端的请求request，直接在URL上产生区分，而不是在URL param_name上才产生区分
// 所以，name或者key，不再会有"ext"或者"ext_"前缀。

//通用的js function：一旦json传回switchToLogin，跳转到login page。
//比如，timeout case和同一账号多点登录case。
// 网页端switchToLoginPage(jsonObj)，插件端switchToExtLoginPage(jsonObj, pubmedWindowOrigin)
function switchToExtLoginPage(jsonObj, redo_code) {
    if (jsonObj == null || (jsonObj.code == null && jsonObj.ok == null))
        // 没有明确示意switch_to_login，不排除网络因素，所以并不实施跳转login。
        return false;

    if (jsonObj.code != null || (jsonObj.ok != null && jsonObj.ok == 'OK')) {
        // 取得下发auto_born_cross_origin_single_session_id_str字符串，并更新到window.localStorage。
        if (jsonObj.pwda != null && jsonObj.pwda.trim().length > 0)
            setSuffixMeSHJsLocalStorage(jsonObj.pwda, jsonObj.uac);

        if (jsonObj.code == "-1" || (jsonObj.switchToLogin != null && jsonObj.switchToLogin == 'TRUE')) {
            // 明确示意了switch_to_login：插件端，由<iframe>发送消息给pubmed Page JS，完成网页切换到login page。
            var data = {};
            data.action = "switchToExtLoginPage";
            // 在switch to ext login用例，发送redo_code，存储在PubMed网页JS全局变量global_redo_code_after_login。
            // 后继，用户完成login，在"hideExtLoginPage"用例，根据该redo_code发送消息给正确的<iframe>，启动该redo。
            data.redo_code = redo_code;

            postMessage2PubMed(data);// 这里"PubMed"是指“PubMed网页JS”。

            return true;
        }
    }
    return false;
}

function switchToLoginPage(jsonObj) {
    if (jsonObj == null || (jsonObj.code == null && jsonObj.ok == null))
        // 没有明确示意switch_to_login，不排除网络因素，所以并不实施跳转login。
        return false;

    if (jsonObj.code != null || (jsonObj.ok != null && jsonObj.ok == 'OK')) {
        // 取得下发auto_born_cross_origin_single_session_id_str字符串，并更新到window.localStorage。
        if (jsonObj.pwda != null && jsonObj.pwda.trim().length > 0)
            setSuffixMeSHJsLocalStorage(jsonObj.pwda, jsonObj.uac);

        if (jsonObj.code == "-1" || (jsonObj.switchToLogin != null && jsonObj.switchToLogin == 'TRUE')) {
            // 明确示意了switch_to_login。
            window.top.location.assign(urlPrefix);// location.assign() 方法加载新文档。如果使用 location.replace()，则无法使用“后退”导航回原始文档。
            return true;
        }
        return false;
    }
}

// 存在短信验证码的网页，接收下行JSON，响应“短信验证码不匹配”行为。
// 所有短信验证码用例，VUE端，都遵循该统一标准。
function verifyCodeMATCHOK(jsonObj, upVerifyCode, cachedLocalVerifyCodesSet) {
    if (jsonObj == null || (jsonObj.code == null && jsonObj.ok == null))
        // 没有明确示意verifyCode_MATCH，不排除网络因素，所以什么也不做，return true;
        return true;

    // 上行的短信校验码做本地缓存：无论是否匹配。
    cachedLocalVerifyCodesSet.add(upVerifyCode);

    if (jsonObj.verifyCode_MATCH != null && jsonObj.verifyCode_MATCH == "FALSE") {
        // 明确：短信验证码不匹配。
        alert("本次 \"" + upVerifyCode + "\" 不匹配；原短信校验码已不再有效，请重新获取。\n\n温馨提示：\n如果超过每日限制，请使用第三方ID。感谢您的谅解和支持。");
        return false;
    }

    return true;

}

function doLoginSuccessIdStrUAC(pwd, uac) {
    // 只在session_born用例，才下发single_sessionId_str。
    // single_sessionId_str字符串存储在Window.localStorage，下发更新，跨页面和生命周期。
    setSuffixMeSHJsLocalStorage(pwd, uac);

    // location.assign() 方法加载新文档。如果使用 location.replace()，则无法使用“后退”导航回原始文档。
    window.top.location.assign(urlPrefix + "/#/search");
}

function doExtLoginSuccessIdStrUAC(pwd, uac) {
    // 只在session_born用例，才下发single_sessionId_str。
    // single_sessionId_str字符串存储在Window.localStorage，下发更新，跨页面和生命周期。
    setSuffixMeSHJsLocalStorage(pwd, uac);

    // postMessage()方式隐藏Login <iframe>。
    var data = {};
    data.action = "hideExtLoginPage";

    // 1、该条消息### 只会 ###发送给window.top窗口。
    // 2、一次性发送2条信息给同一个窗口（即window.top），分别指定Origin。
    postMessage2PubMed(data);
}

//对给定的allKeywords，解析出需要高亮的全部tokens：预先做了小写转换。
function total_lowercase_tokens_to_highlight(allKeywords) {
    var tokens_to_highlight = [];
    if (allKeywords == null)
        return tokens_to_highlight;
    for (var i = 0; i < allKeywords.length; i++) {
        var keyword = allKeywords[i];
        keyword = removePunctuations(keyword);
        var local_tokens = keyword.split(" ");
        for (var j = 0; j < local_tokens.length; j++) {
            var localToken = local_tokens[j].toLowerCase();//采用转换小写后匹配。
            if (localToken.length > 0) {
                var same = false;//不添加重复的token
                for (var k = 0; k < tokens_to_highlight.length; k++)
                    if (localToken == tokens_to_highlight[k]) {
                        same = true;
                        break;
                    }
                if (!same) {
                    tokens_to_highlight.push(localToken);
                }
            }
        }
    }
    return tokens_to_highlight;
}
// 对给定的字符串tokens_str，使用token转换小写后匹配并Array.join做成HTML highlight格式字符串。
// 客户端caller对red_or_black可以不予输入，默认为0：red。
function do_lowercase_token_join_red_itatic_highlight(tokens_str, tokens_to_highlight, red_or_black = 0) {
    if (tokens_to_highlight == null || tokens_to_highlight.length == null || tokens_to_highlight.length <= 0)
        return;
    var tokens = tokens_str.split(" ");
    var do_highlight = false;
    if (red_or_black == null || (red_or_black != 0 && red_or_black != 1))//red_or_black要么为0（red），要么为1（black）。
        red_or_black = 0;//默认为red：更好看些，不刺眼。
    var colorStr = red_or_black == 0 ? "red" : "black";
    for (var i = 0; i < tokens.length; i++) {
        var token = tokens[i];
        for (let j = 0; j < tokens_to_highlight.length; j++)
            if (token_match_to_highlight(token, tokens_to_highlight[j])) {
                // tokens[i] = this.highlight_tokens_html[j];//预先做成HTML highlight格式字符串："<strong style=\"color: red\"><i>" + token_light + "</i></strong>";
                // tokens[i] = "<strong style=\"color: " + colorStr + "\"><i>" + token + "</i></strong>";//采用转换小写后匹配，所以无法预先做成HTML highlight格式字符串。
                tokens[i] = "<i style=\"color: " + colorStr + "\">" + token + "</i>";//采用转换小写后匹配，所以无法预先做成HTML highlight格式字符串。
                do_highlight = true;
            }
    }
    // 对高亮字符串添加<span>，结合searchContent.vue中的<template>中的<span v-html=""></span>样式，获得红色斜体高亮。
    return do_highlight ? "<span>" + tokens.join(" ") + "</span>" : tokens_str;
}

// 在字符串高亮场景下，匹配字符串的算法：转换小写和indexOf(length > 3)匹配，用户体验更佳。
// 默认to_highlight_token已经过了toLowerCase()处理，所以不再转换小写。
// 对应到服务器端源代码com.medwords.common.Utility.isLowercaseEqualsOrIndexOf(String, String)。
function token_match_to_highlight(token, to_highlight_token) {
    // 采用转换小写后匹配！用户体验更佳！
    token = token.toLowerCase();
    // 默认to_highlight_token已经过了toLowerCase()处理，所以不再转换小写。
    // to_highlight_token = to_highlight_token.toLowerCase();

    // 采用indexOf(token_to_highlight)而不是"=="，用户体验更佳！
    return to_highlight_token.length > 3 ?
        token.indexOf(to_highlight_token) >= 0 :
        (to_highlight_token.length == 3 ?
            (token.startsWith(to_highlight_token) || token.endsWith(to_highlight_token)) : token == to_highlight_token);
}

// 在字符串高亮场景下，匹配字符串的算法：转换小写和indexOf(length > 3)匹配，用户体验更佳。
// 注意此时tokenStr是（比如多个token组成的）字符串，而不限定是token。
// 默认lowercase_tokens_to_highlight已经过了toLowerCase()处理，所以不再逐个转换小写。
function tokenStr_match_to_highlight(tokenStr, lowercase_tokens_to_highlight) {
    // 采用转换小写后匹配！用户体验更佳！
    var lowercase_tokens = tokenStr.toLowerCase().split(" ");
    // 默认tokens_to_highlight已经过了toLowerCase()处理，所以不再逐个转换小写。
    for (var i = 0; i < lowercase_tokens.length; i++) {
        var lowercase_token = lowercase_tokens[i];
        for (let j = 0; j < lowercase_tokens_to_highlight.length; j++) {
            var to_highlight_token = lowercase_tokens_to_highlight[j];
            // 匹配算法几度更新，所以统一调用token_match_to_highlight()方法。
            // if (to_highlight_token.length > 3 ?
            //     lowercase_token.indexOf(to_highlight_token) >= 0 : lowercase_token == to_highlight_token)
            if (token_match_to_highlight(lowercase_token, to_highlight_token))
                return true;
        }
    }
    return false;
}

var common_ignored_punctuations = [",", "，", ".", "。", ";", "；", "[", "]",
    "{", "}", "(", ")", "/", "|", "'", "‘", "’", "\"", "“", "”", "?", "!", "！"];

function removePunctuations(kwd_str, ignored_punctuations) {
    if (ignored_punctuations == null)
        ignored_punctuations = common_ignored_punctuations;
    for (var i = 0; i < ignored_punctuations.length; i++)
        kwd_str = kwd_str.replaceAll(ignored_punctuations[i], "");
    return kwd_str;
}

// 拼接全部keywords成为url中的query参数
function urlQueryArray(queryArray, countStr, keywordPrefix) {
    var count_keywords = queryArray.length;
    if (count_keywords == 0)
        return countStr + "=" + count_keywords;//结尾不带 "&"

    // 那么queryArray.length > 0
    var url_query_str = countStr + "=" + count_keywords + "&";
    // 只做到queryArray.length - 1
    for (var index = 0; index < queryArray.length - 1; index++)
        url_query_str += keywordPrefix + index + "=" + encodeURIComponent(queryArray[index]) + "&";
    // 最后一个。且不带"&"结束。
    url_query_str += keywordPrefix + (queryArray.length - 1) + "=" + encodeURIComponent(queryArray[queryArray.length - 1])

    return url_query_str;//结尾不带 "&"
}

// 每次Request都提交crtUAC字符串。所以该方法直接归由net.js调用，不必分散在各处的Request显式地调用。
// crtUAC字符串存储在Window.localStorage，实时读取，跨页面和生命周期。
function getCrtUACLocalStorage() {
    try {
        if (window.localStorage) {
            const localUAC = window.localStorage.getItem(crtUAC);
            if (localUAC == null || localUAC.trim() == "")
                return null;
            return localUAC;
        }
    } catch (e) {
        console.error("*** MeSH Proper：window.localStorage.getItem(\"10001\") failed!");
        return null;
    }
}

// 每次Request都提交single_sessionId_str字符串。所以该方法直接归由net.js调用，不必分散在各处的Request显式地调用。
// single_sessionId_str字符串存储在Window.localStorage，实时读取，跨页面和生命周期。
function getSuffixMeSHJsLocalStorage() {
    try {
        if (window.localStorage) {
            var localUAC = getCrtUACLocalStorage();
            if (localUAC == null || localUAC.trim() == "")
                localUAC = "null";
            // 引入NoStrId后，区分/隔离每个userId的NoStrId，浏览器端需要userAccount字符串拼接suffixMeSHJs key。
            // 每次上行，也必须提交userAccount字符串验证上行singleSessionIdStr与userId/userAccount是正确对应的。
            return window.localStorage.getItem(suffixMeSHJs + "_" + localUAC);
        }
    } catch (e) {
        console.error("*** MeSH Proper：window.localStorage.getItem(\"10001\") failed!");
        return null;
    }
    return null;
}

// 只在session_born用例，才下发single_sessionId_str。
// single_sessionId_str字符串存储在Window.localStorage，下发更新，跨页面和生命周期。
// 引入NoStrId后，区分/隔离每个userId的NoStrId，浏览器端需要userAccount字符串拼接suffixMeSHJs key。
// 服务器端只从HttpRequest中，按固定数量固定名称，提取LOGIN_UAC_CANDIDATES。
function setSuffixMeSHJsLocalStorage(single_sessionId_str, downUAC) {
    if (downUAC == null || downUAC.trim() == ""
        || single_sessionId_str == null || single_sessionId_str.trim() == "")
        return;
    downUAC = downUAC.trim();
    try {
        if (window.localStorage) {
            // 饱和式if
            if (TOTAL_UAC_NAMES == null) {
                TOTAL_UAC_NAMES = [];
                // 服务器端只从HttpRequest中，按固定数量固定名称，提取LOGIN_UAC_CANDIDATES。
                for (var i = 0; i < NUM_OF_TOTAL_UAC; i++)
                    TOTAL_UAC_NAMES.push(TOTAL_UAC_PREFIX + i);// "total_UAC_0~9";
            }
            // 遍历全部total_UAC：如果下发userAccount不存在于其中，必须新增该userAccount。
            var count = 0;
            for (; count < TOTAL_UAC_NAMES.length; count++) {
                // "total_UAC_0~9";
                const itemNameOfUAC = TOTAL_UAC_NAMES[count];
                // localStorage存储的1个账号UAC，比如"liuhong#1705889463910"。
                const aLocalUAC = window.localStorage.getItem(itemNameOfUAC);
                if (aLocalUAC == null) {
                    // 新增该userAccount
                    window.localStorage.setItem(itemNameOfUAC, downUAC);
                    break;// 此时，count < TOTAL_UAC_NAMES.length
                } else if (aLocalUAC == downUAC)
                    break;// 此时，count < TOTAL_UAC_NAMES.length
            }
            if (count >= TOTAL_UAC_NAMES.length) {// 那么，由TOTAL_UAC_NAMES定义的userAccount已满，于是
                // (1) 淘汰首位userAccount，全部userAccount按TOTAL_UAC_NAMES前移
                for (var i = 0; i < TOTAL_UAC_NAMES.length - 1; i++)
                    window.localStorage.setItem(TOTAL_UAC_NAMES[i], window.localStorage.getItem(TOTAL_UAC_NAMES[i + 1]));

                // (2) 新增userAccount增补在末位。
                window.localStorage.setItem(TOTAL_UAC_NAMES[TOTAL_UAC_NAMES.length - 1], downUAC);
            }

            // 引入NoStrId后，区分/隔离每个userId的NoStrId，浏览器端需要userAccount字符串拼接suffixMeSHJs key。
            // 每次上行，也必须提交userAccount字符串验证上行singleSessionIdStr与userId/userAccount是正确对应的。
            window.localStorage.setItem(crtUAC, downUAC);
            window.localStorage.setItem(suffixMeSHJs + "_" + downUAC, single_sessionId_str);

            // console.debug("*** MeSH Proper：crtUAC = " + downUAC + " , " + single_sessionId_str);

        }
    } catch (e) {
        console.error("*** MeSH Proper：window.localStorage.setItem(\"10001\") failed!");
    }
}

// localStorage存储的UAC总数：与服务器端一致。
const NUM_OF_TOTAL_UAC = 10; // 5 ?

var TOTAL_UAC_NAMES = null;
const TOTAL_UAC_PREFIX = "t_UAC_";// "total_UAC_"：与NoStrIdCoupleSingleSessionUtility.SESSION_BORN_UAC_NAME_PREFIX一致。
const TOTAL_UAC_STR_PREFIX = "suffixMeSH_";// 与NoStrIdCoupleSingleSessionUtility.SESSION_BORN_ID_STR_PARAM_NAME一致。

// * LOGIN_UAC_CANDIDATES架构下，只在LOGIN/REGISTER用例，上行全部total_UAC/suffixMeSH_localUAC存储，<br>
//  * 由服务器端从中筛选正确的upUserId/upNoStrId；如果没有则使用nextNoStrId()。
// * <p>
// * 从window.localStorage取得全部上行upSingleSessionIdStr字符串。
function getSuffixSessionBornURLStr() {
    var reqURLStr = "";

    // NoStrIdCoupleSingleSessionManageTaker.upSessionIdStrsOnBornReq(HttpServletRequest, String)
    // "total_UAC_*"。*：0~9。
    // "suffixMeSH_" + upUAC
    // 如上所示，全部upUAC(userAccount)：localStorage.getItem("total_UAC_*")，
    // 拼接全部localStorage.getItem("suffixMeSH_" + upUAC)，
    // 得到全部上行upSingleSessionIdStr字符串
    if (TOTAL_UAC_NAMES == null) {
        TOTAL_UAC_NAMES = [];
        // 服务器端只从HttpRequest中，按固定数量固定名称，提取LOGIN_UAC_CANDIDATES。
        for (var i = 0; i < NUM_OF_TOTAL_UAC; i++)
            TOTAL_UAC_NAMES.push(TOTAL_UAC_PREFIX + i);// "total_UAC_0~9";
    }

    try {
        if (window.localStorage) {
            // 固定地按照"total_UAC_0~9"/"suffixMeSH_" + aLocalUAC的字符串取得localStorage存储的
            // 全部userAccount及其对应的上行upSingleSessionIdStr字符串。
            // 然后拼接成为reqURLStr字符串。
            for (var i = 0; i < TOTAL_UAC_NAMES.length; i++) {
                // "total_UAC_0~9";
                const itemNameOfUAC = TOTAL_UAC_NAMES[i];
                // localStorage存储的1个账号UAC，比如"liuhong#1705889463910"。
                const aLocalUAC = window.localStorage.getItem(itemNameOfUAC);
                if (aLocalUAC == null || aLocalUAC.trim() == "" || aLocalUAC.indexOf("#") < 0)
                    continue;
                // "suffixMeSH_" + aLocalUAC
                const itemNameOfIdStr = TOTAL_UAC_STR_PREFIX + aLocalUAC;
                // 该账号存储在localStorage的上行upSingleSessionIdStr字符串
                const upIdStr = window.localStorage.getItem(itemNameOfIdStr);
                if (upIdStr == null || upIdStr.trim() == "")
                    continue;
                // 有效的账号和上行upSingleSessionIdStr字符串，拼接HttpRequest URL。
                reqURLStr += "&" + itemNameOfUAC + "=" + encodeURIComponent(aLocalUAC) +
                    "&" + itemNameOfIdStr + "=" + encodeURIComponent(upIdStr);
            }
        }
    } catch (e) {
        console.error("*** MeSH Proper：window.localStorage.getItem(\"10009\") failed!");
        return null;
    }

    return reqURLStr;
}

// 从localStorage载入全部"total_UAC_0~9"和对应的UAC names字符串。
function loadTotalUACAndIdStrs() {
    if (TOTAL_UAC_NAMES == null) {
        TOTAL_UAC_NAMES = [];
        // 服务器端只从HttpRequest中，按固定数量固定名称，提取LOGIN_UAC_CANDIDATES。
        for (var i = 0; i < NUM_OF_TOTAL_UAC; i++)
            TOTAL_UAC_NAMES.push(TOTAL_UAC_PREFIX + i);// "total_UAC_0~9";
    }

    const totalUACAndIdStrs = [];
    try {
        if (window.localStorage) {
            // 固定地按照"total_UAC_0~9"/"suffixMeSH_" + aLocalUAC的字符串取得localStorage存储的
            // 全部userAccount及其对应的上行upSingleSessionIdStr字符串。
            // 然后拼接成为reqURLStr字符串。
            for (var i = 0; i < TOTAL_UAC_NAMES.length; i++) {
                // "total_UAC_0~9";
                const itemNameOfUAC = TOTAL_UAC_NAMES[i];
                // localStorage存储的1个账号UAC，比如"liuhong#1705889463910"。
                const aLocalUAC = window.localStorage.getItem(itemNameOfUAC);
                if (aLocalUAC == null || aLocalUAC.trim() == "" || aLocalUAC.indexOf("#") < 0)
                    continue;
                // "suffixMeSH_" + aLocalUAC
                const itemNameOfIdStr = TOTAL_UAC_STR_PREFIX + aLocalUAC;
                // 该账号存储在localStorage的上行upSingleSessionIdStr字符串
                const upIdStr = window.localStorage.getItem(itemNameOfIdStr);
                if (upIdStr == null || upIdStr.trim() == "")
                    continue;
                // 有效的账号和上行upSingleSessionIdStr字符串
                totalUACAndIdStrs.push([aLocalUAC, upIdStr]);
            }
        }
    } catch (e) {
        console.error("*** MeSH Proper：window.localStorage.getItem(\"10009\") failed!");
        return null;
    }

    return totalUACAndIdStrs;
}

// 如果存在userAccount = null，clear全部TOTAL_UAC_NAMES/userAccount，维护正常状态：
// 相比于可能的Attack或者用户误操作，维护浏览器正常状态和可用性，更为重要。
// 
// 对window.localStorage.removeItem()：如果没有与该给定键名匹配的项，则此方法将不执行任何操作：
// https://developer.mozilla.org/zh-CN/docs/Web/API/Storage/removeItem
function clearTotalUAC() {
    TOTAL_UAC_NAMES = [];
    // 服务器端只从HttpRequest中，按固定数量固定名称，提取LOGIN_UAC_CANDIDATES。
    for (var i = 0; i < NUM_OF_TOTAL_UAC; i++)
        TOTAL_UAC_NAMES.push(TOTAL_UAC_PREFIX + i);// "total_UAC_0~9";

    try {
        if (window.localStorage) {
            // 固定地按照"total_UAC_0~9"/"suffixMeSH_" + aLocalUAC的字符串取得localStorage存储的
            // 全部userAccount及其对应的上行upSingleSessionIdStr字符串。
            // 然后拼接成为reqURLStr字符串。
            for (var i = 0; i < TOTAL_UAC_NAMES.length; i++) {
                // "total_UAC_0~9";
                const itemNameOfUAC = TOTAL_UAC_NAMES[i];
                // localStorage存储的1个账号UAC，比如"liuhong#1705889463910"。
                const aLocalUAC = window.localStorage.getItem(itemNameOfUAC);
                if (aLocalUAC != null) {
                    const itemNameOfIdStr = TOTAL_UAC_STR_PREFIX + aLocalUAC;
                    // 账号aLocalUAC存储在localStorage的上行upSingleSessionIdStr字符串
                    window.localStorage.removeItem(itemNameOfIdStr);
                }
                window.localStorage.removeItem(itemNameOfUAC);
            }
        }
    } catch (e) {
        console.error("*** MeSH Proper：window.localStorage.getItem(\"10009\") failed!");
        return null;
    }
}

// 引入pre_session_str，架构CPU计算结构加密字符串，来对抗NoStrIdSessionStr之前的爬虫攻击。
function getSuffiLocalStorage() {
    try {
        if (window.localStorage) {
            const localSuffi = window.localStorage.getItem(suffi);
            if (localSuffi == null || localSuffi.trim() == "")
                return null;
            return localSuffi;
        }
    } catch (e) {
        console.error("*** MeSH Proper：window.localStorage.getItem(\"10002\") failed!");
        return null;
    }
}

// 引入pre_session_str，架构CPU计算结构加密字符串，来对抗NoStrIdSessionStr之前的爬虫攻击。
function setSuffiLocalStorage(suffi_str) {
    try {
        if (window.localStorage) {
            if (suffi_str == null || suffi_str.trim() == "")
                suffi_str = "null";

            window.localStorage.setItem(suffi, suffi_str);

            console.debug("*** MeSH Proper：set suffi = " + suffi_str);
        }
    } catch (e) {
        console.error("*** MeSH Proper：window.localStorage.setItem(\"10002\") failed!");
    }
}

// 在新的窗口打开PDF。
// 对/pdf.mesh，包括"/extensions/pdf.mesh"和"/pdf.mesh"用例，统一的架构是，
// 不直接请求数据/pdf.mesh，而是请求OpenPDFView.vue：提交localStorage中的singleSessionIdStr。
function openMeSHPDFView(pmcid, calledByExt) {
    if (pmcid != null && pmcid.length > 0 && pmcid.startsWith("PMC")) {
        var url = null;// 请求OpenPDFView.vue而不是请求pdf.mesh
        if (calledByExt)
            // url = "localhost:8080/#/extensions/openPDF?key=" + pmcid;
            url = urlPrefix + "/#/extensions/openPDF?key=" + pmcid;
        else
            // url = "localhost:8080/#/openPDF?key=" + pmcid;
            url = urlPrefix + "/#/openPDF?key=" + pmcid;
        // 无论是否位于<iframe>内部，浏览器允许window.open(url);都是在浏览器top打开新的页面。
        // 只是window.top.location.assign(url);被跨域禁止访问window.top.location。
        window.open(url);
    } else
        alert("该PDF暂不能浏览。给您带来不便，深表歉意。谢谢理解支持。");
}

// Microsoft Edge Addon官方商店
function edgeOfficialMeSHProper() {
    window.open("https://microsoftedge.microsoft.com/addons/detail/kgginlgphekolbdkbiienaedlgkpjlce");
}

// 导流跳转URL既可以使用官网端也可以使用插件端（"/extensions/"前缀），甚至使用URL上的查询字符串参数Param也可以在JS mounted()引入自动请求search。
const homeSearchURL = urlPrefix + "/#/search";//导流到官网，默认到达MeSH Proper Search页面。

//导流到官网，默认到达MeSH Proper Search页面。
function gotoHomeSearch(pubmedSearchKeywords, actionName) {
    var homeURL = null;
    var single_sessionId_str = getSuffixMeSHJsLocalStorage();
    if (single_sessionId_str == null || single_sessionId_str == "")
        // 仅仅是到达MeSH Proper Home。
        homeURL = urlPrefix;
    else
        // 导流跳转URL既可以使用官网端也可以使用插件端（"/extensions/"前缀），甚至使用URL上的查询字符串参数Param也可以在JS mounted()引入自动请求search。
        // 导流跳转URL不是数据请求URL，仅仅是请求web静态网页，由VUE返回（经index.js配置即可），与浏览器origin无关。
        homeURL = homeSearchURL;
    // console.debug("*** common.js - gotoHomeSearch()：homeURL = " + homeURL + " , actionName = " + actionName);
    // console.debug("*** common.js - gotoHomeSearch()：window.location.origin = " + window.location.origin
    //     + "  ,  window.localStorage.getItem() = " + getSuffixMeSHJsLocalStorage());

    // 跨域访问异常：在<iframe>之内，发起window.open(URL)到达MeSH Proper官网。
    // DOMException: Failed to read a named property 'open' from 'Window': Blocked a frame with origin "https://www.meshproper.com" from accessing a cross-origin frame.
    // var homeWindow = window.top.open(homeURL);

    // 所以只能采用postMessage();
    var data = {};
    data.action = actionName;//"gotoHomeSearch"或"gotoHomeSearchComposite"
    data.crtUAC = getCrtUACLocalStorage();
    data.homeURL = homeURL;
    data.searchKeyword = pubmedSearchKeywords;

    postMessage2PubMed(data);
}

// 本地代码（<iframe>中）无法访问和验证window.top.location。
// 1、使用window.top获得PubMed网页引用，发送消息：该条消息### 只会 ###发送给该窗口（即window.top）。
// 2、运行时，window.top的Origin随时可以改变，
//        所以浏览器调度消息发送时刻，window.top的Origin必须是pmcPageOrigin/pubmedPageOrigin。
// 3、所以一次性发送2条信息给同一个窗口（即window.top），分别指定Origin。
function postMessage2PubMed(msgData) {
    window.top.postMessage(msgData, pmcPageOrigin);
    window.top.postMessage(msgData, pubmedPageOrigin);
}

function openAgreement() {
    window.open(urlPrefix + "/#/agreement");
    // window.open("http://localhost:8080/#/agreement");
}

function openAccountBind() {
    window.open(urlPrefix + "/#/accountAgreement");
    // window.open("http://localhost:8080/#/accountAgreement");
}

function openPrivacy() {
    window.open(urlPrefix + "/#/statementAndPrivacy");
    // window.open("http://localhost:8080/#/statementAndPrivacy");
}

function openAboutUs() {
    window.open(urlPrefix + "/#/aboutUs");
    // window.open("http://localhost:8080/#/aboutUs");
}

function openFeedback() {
    window.open(urlPrefix + "/#/userFeedback");
    // window.open("http://localhost:8080/#/userFeedback");
}


export { switchToLoginPage, switchToExtLoginPage, doLoginSuccessIdStrUAC, doExtLoginSuccessIdStrUAC, openMeSHPDFView, gotoHomeSearch, postMessage2PubMed }
export { urlQueryArray, tokenStr_match_to_highlight, do_lowercase_token_join_red_itatic_highlight, total_lowercase_tokens_to_highlight, token_match_to_highlight }
export { getCrtUACLocalStorage, getSuffixMeSHJsLocalStorage, setSuffixMeSHJsLocalStorage, getSuffixSessionBornURLStr, loadTotalUACAndIdStrs, verifyCodeMATCHOK }
export { getSuffiLocalStorage, setSuffiLocalStorage }
export { edgeOfficialMeSHProper, openAgreement, openAccountBind, openPrivacy, openAboutUs, openFeedback }
export { urlPrefix, suffixMeSHJs, crtUAC, crtUACa, crtUACaNE, reqAutoPubMeSH, pmcPageOrigin, pubmedPageOrigin }

// //页面上预留的<form>，发起submit()
// function actionFormSubmit(actionUrl) {
//     var form = document.getElementById("actionForm");
//     form.action = actionUrl;
//     form.submit();
// }

// //login case需要clear suffixNetJs的value
// function clearSuffixNetJsParam() {
//     var suffixNetJsObj = document.getElementById("suffixNetJs");
//     if (suffixNetJsObj != null)
//         suffixNetJsObj.value = "";
// }

