import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
// import HomeView from '../views/HomeView_2video.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  // 对网页形式的页面Page，插件端和官网端使用同一个SearchView.vue文件，但是URL path不同：根据URL path决定业务计算逻辑。
  // 插件端和官网端使用不同的URL path：插件端始终是"/extensions/"前缀
  {
    path: '/search',
    name: 'search',
    component: () => import('../views/SearchView.vue')
  },
  // 对网页形式的页面Page，插件端和官网端使用同一个SearchView.vue文件，但是URL path不同：根据URL path决定业务计算逻辑。
  // 插件端和官网端使用不同的URL path：插件端始终是"/extensions/"前缀
  {
    path: '/extensions/search',
    name: 'extSearch',
    component: () => import('../views/SearchView.vue')
  },
  {
    path: '/PaymentView',
    name: 'PaymentView',
    component: () => import('../views/PaymentView.vue')
  },
  {
    path: '/payDetails',
    name: 'payDetails',
    component: () => import('../views/PayDetails.vue')
  },
  // 自顶向下的啥子名字浮窗
  {
    path: '/plugInSearch',
    name: 'plugInSearch',
    component: () => import('../views/PlugInSearch.vue')
  },
  // {
  //   // 搜索（7个）标题简表浮窗：无鼠标悬浮效果
  //   path: '/titleFloatView',//'/extensions/titleFloatWindow'
  //   name: 'titleFloatView',
  //   component: () => import('../views/TitleFloatView.vue')
  // },
  {
    path: '/extensions/extHome',
    name: 'extHome',
    component: () => import('../views/ExtHomeView.vue')
  },
 {
    // 搜索（5个）标题简表浮窗：取消鼠标悬浮效果
    path: '/extensions/titles5Search',
    name: 'titles5Search',
    component: () => import('../views/Titles5Search.vue')
  },
  {
    // 插件端搜索提示词列表浮窗：整体涵盖了两行输入框和搜索提示词列表，无权重值checkbox。
    // 网页端搜索提示词列表没有单独的浮窗：SearchView.vue整体涵盖了网页搜索。
    path: '/extensions/searchWordsFloat',
    name: 'extensions/searchWordsFloat',
    component: () => import('../views/SearchWordsFloat.vue')
  },
  {
    // 插件端数据列表浮窗
    path: '/extensions/titleFloatWindow',
    name: 'titleFloatWindow',
    component: () => import('../views/TitleFloatWindow.vue')
  },
  {
    // openPDF -- 来自插件端请求View页面
    path: '/extensions/openPDF',
    name: 'extOpenPDF',
    component: () => import('../views/OpenPDFView.vue')
  },
  {
    // openPDF -- 来自官网端请求View页面
    path: '/openPDF',
    name: 'openPDF',
    component: () => import('../views/OpenPDFView.vue')
  },
  {
    // logo
    path: '/logoShow',
    name: 'logoShow',
    component: () => import('../views/staticShow/LogoShow.vue')
  },
  {
    // 通用页面aboutUs关于我们
    path: '/aboutUs',
    name: 'aboutUs',
    component: () => import('../views/staticShow/AboutUsView.vue')
  },
  {
    // 法律声明暨隐私权政策
    path: '/statementAndPrivacy',
    name: 'statementAndPrivacy',
    component: () => import('../views/staticShow/StatementAndPrivacy.vue')
  },
  {
    // MeSH Proper 服务条款
    path: '/agreement',
    name: 'agreement',
    component: () => import('../views/staticShow/AgreementView.vue')
  },
  {
    // 第三方账号绑定协议
    path: '/accountAgreement',
    name: 'accountAgreement',
    component: () => import('../views/staticShow/AccountAgreement.vue')
  },
  {
    // 用户反馈
    path: '/userFeedback',
    name: 'userFeedback',
    component: () => import('../views/staticShow/UserFeedback.vue')
  },
]

const router = new VueRouter({
  routes
})

export default router
