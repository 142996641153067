/*
url-loading object and a request queue built on top of it
 */

/* namespacing object */

import { getSuffixMeSHJsLocalStorage, suffixMeSHJs, getCrtUACLocalStorage, crtUAC, getSuffiLocalStorage } from "@/utils/common.js"

var net = new Object();

net.READY_STATE_UNINITIALIZED = 0;
net.READY_STATE_LOADING = 1;
net.READY_STATE_LOADED = 2;
net.READY_STATE_INTERACTIVE = 3;
net.READY_STATE_COMPLETE = 4;

/*--- content loader object for cross-browser requests ---*/
net.ContentLoader = function (url, onload, onerror, method, params, localObj, contentType) {
	this.req = null;
	this.onload = onload;
	this.onerror = (onerror) ? onerror : this.defaultError;
	this.loadXMLDoc(url, method, params + suffixNetJsParam(), contentType);
	this.localObj = localObj;
}

net.ContentLoader.prototype = {
	loadXMLDoc: function (url, method, params, contentType) {
		if (!method) {
			method = "GET";
		}
		if (!contentType && method == "POST") {
			contentType = 'application/x-www-form-urlencoded';
		}
		if (window.XMLHttpRequest) {
			this.req = new XMLHttpRequest();
		} else if (window.ActiveXObject) {
			this.req = new ActiveXObject("Microsoft.XMLHTTP");
		}
		if (this.req) {
			try {
				var loader = this;
				this.req.onreadystatechange = function () {
					loader.onReadyState.call(loader);
				}
				// 注释掉该句，以防在浏览器控制台出现代码痕迹和显示源代码。
				// console.debug(url);
				this.req.open(method, url, true);
				if (contentType) {
					this.req.setRequestHeader('Content-Type', contentType);
				}
				this.req.send(params);
			} catch (err) {
				this.onerror.call(this);
			}
		}
	},

	onReadyState: function () {
		var req = this.req;
		var ready = req.readyState;
		//var httpStatus = req.status;//因为unspecified error而不再有local引用
		if (ready == net.READY_STATE_COMPLETE) {
			if (req.status == 200 || req.status == 0) {
				this.onload.call(this);
			} else {
				this.onerror.call(this);
			}
		}
	},

	defaultError: function () {
		//alert("error fetching data!" + "\n\nreadyState:" + this.req.readyState
		//		+ "\nstatus: " + this.req.status + "\nheaders: "
		//		+ this.req.getAllResponseHeaders());
		if (this.req.status == 404 || this.req.status == 503)
			alert("出现了一个异常。很抱歉给您带来的不便。请稍后尝试访问网站服务，谢谢。");
		else if (this.req.status == 12029)
			alert("与网站的连接已中断。很抱歉给您带来的不便。请稍后尝试访问网站服务，谢谢。");
		else
			alert("网络错误，请稍后重新尝试。谢谢。");
	}
}

/*--- queue system for efficient transfer of many small commands in a single request ---*/
net.cmdQueues = new Array();

net.CommandQueue = function (id, url, onUpdate, freq) {
	this.id = id;
	net.cmdQueues[id] = this;
	this.url = url;
	this.queued = new Array();
	this.sent = new Array();
	this.onUpdate = onUpdate;
	if (freq) {
		this.repeat(freq);
	}
	this.lastUpdateTime = 0;
}

net.CommandQueue.STATUS_QUEUED = -1;
net.CommandQueue.STATE_UNINITIALIZED = net.READY_STATE_UNINITIALIZED;
net.CommandQueue.STATE_LOADING = net.READY_STATE_LOADING;
net.CommandQueue.STATE_LOADED = net.READY_STATE_LOADED;
net.CommandQueue.STATE_INTERACTIVE = net.READY_STATE_INTERACTIVE;
net.CommandQueue.STATE_COMPLETE = net.READY_STATE_COMPLETE;
net.CommandQueue.STATE_PROCESSED = 5;

net.CommandQueue.PRIORITY_NORMAL = 0;
net.CommandQueue.PRIORITY_IMMEDIATE = 1;

net.CommandQueue.prototype = {
	addCommand: function (command) {
		if (this.isCommand(command)) {
			this.queue.append(command, true);
			if (command.priority == net.CommandQueue.PRIORITY_IMMEDIATE) {
				this.fireRequest();
			}
		}
	},

	fireRequest: function () {
		if (!this.onUpdate && this.queued.length == 0) {
			return;
		}
		var data = "lastUpdate=" + this.lastUpdateTime + "&data=";
		for (var i = 0; i < this.queued.length; i++) {
			var cmd = this.queued[i];
			if (this.isCommand(cmd)) {
				data += cmd.toRequestString();
				this.sent[cmd.id] = cmd;
			}
		}
		this.queued = new Array();
		this.loader = new net.ContentLoader(this.url, net.CommandQueue.onload,
			net.CommandQueue.onerror, "POST", data);
	},

	isCommand: function (obj) {
		return (obj.implementsProp("id") && obj.implementsProp("priority")
			&& obj.implementsFunc("toRequestString") && obj
				.implementsFunc("parseResponse"));
	},

	repeat: function (freq) {
		this.unrepeat();
		if (freq > 0) {
			this.freq = freq;
			var cmd = "net.cmdQueues[" + this.id + "].fireRequest()";
			this.repeater = setInterval(cmd, freq * 1000);
		}
	},

	unrepeat: function () {
		if (this.repeater) {
			clearInterval(this.repeater);
		}
		this.repeater = null;
	}
}

net.CommandQueue.onload = function () {
	var xmlDoc = this.req.responseXML;
	var elDocRoot = xmlDoc.getElementsByTagName("responses")[0];
	var lastUpdate = elDocRoot.attributes.getNamedItem("updateTime");
	if (parseInt(lastUpdate) > this.lastUpdateTime) {
		this.lastUpdateTime = lastUpdate;
	}
	if (elDocRoot) {
		for (i = 0; i < elDocRoot.childNodes.length; i++) {
			elChild = elDocRoot.childNodes[i];
			if (elChild.nodeName == "command") {
				var attrs = elChild.attributes;
				var id = attrs.getNamedItem("id").value;
				var command = net.commandQueue.sent[id];
				if (command) {
					command.parseResponse(elChild);
				}
			} else if (elChild.nodeName == "update") {
				if (this.implementsFunc("onUpdate")) {
					this.onUpdate.call(this, elChild);
				}
			}
		}
	}
}

net.CommandQueue.onerror = function () {
	alert("problem sending the data to the server");
}

// net js提交的后缀
function suffixNetJsParam() {
	// var suffixNetJsValue = "";
	// var suffixNetJsObj = document.getElementById("suffixNetJs");
	// if (suffixNetJsObj != null)
	// 	suffixNetJsValue = suffixNetJsObj.value;

	// return "&suffixNetJs=" + encodeURIComponent(suffixNetJsValue) + "&netJs=suffix";

	// 每次Request都提交single_sessionId_str字符串。所以该方法直接归由net.js调用，不必分散在各处的Request显式地调用。
	// single_sessionId_str字符串存储在Window.localStorage，实时读取和下发更新，跨页面和生命周期。
	const reqSuffixMeSHJsStr = getReqSuffixMeSHJsURLStr();
	// 引入pre_session_str，架构CPU计算结构加密字符串，来对抗NoStrIdSessionStr之前的爬虫攻击
	const suffi_str = getSuffiLocalStorage();
	return reqSuffixMeSHJsStr + "&MeshJs=suffix" + "&suffi_str=" + suffi_str;

}


// 每次Request都提交single_sessionId_str字符串。所以该方法直接归由net.js调用，不必分散在各处的Request显式地调用。
// 1、返回suffixMeSHJsStr字符串：该request URL字符串拼接了single_sessionId_str字符串。
// 2.1、引入NoStrId后，区分/隔离每个userId的NoStrId，浏览器端需要userAccount字符串拼接suffixMeSHJs key。
// 2.2、每次上行，也必须提交userAccount字符串验证上行singleSessionIdStr与userId/userAccount是正确对应的。
function getReqSuffixMeSHJsURLStr() {
	var reqURLStr = "";
	const localUAC = getCrtUACLocalStorage();// local本地的账号（UAC）字符串。
	if (localUAC == null || localUAC.trim() == "") {
		// console.error("*** MeSH Proper：localStorage.getItem(crtUAC) = null ！");
		reqURLStr += "&" + crtUAC + "=null";
	}
	else
		//提交userAccount字符串：服务器端将以此验证。
		reqURLStr += "&" + crtUAC + "=" + encodeURIComponent(localUAC);

	const ext_single_sessionId_str = getSuffixMeSHJsLocalStorage();
	if (ext_single_sessionId_str == null || ext_single_sessionId_str.trim() == "") {
		// console.error("*** MeSH Proper：localStorage.getItem(suffixMeSHJs) = null ！");
		reqURLStr += "&" + suffixMeSHJs + "=null";
	}
	else
		reqURLStr += "&" + suffixMeSHJs + "=" + encodeURIComponent(ext_single_sessionId_str);

	return reqURLStr;
}


export { net }
