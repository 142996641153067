<template>
    <div class="main-login">
        <div class="change-way">
            <div class="way" @click="changWay(1)">
                <span :class="[wayID == 1 ? 'ok-change' : 'no-change']" class="name-text">账号注册</span>
                <p :class="[wayID == 1 ? 'ok-line' : 'no-line']" class="line"></p>
            </div>
            <div class="way" @click="changWay(2)">
                <span :class="[wayID == 2 ? 'ok-change' : 'no-change']" class="name-text">短信注册</span>
                <p :class="[wayID == 2 ? 'ok-line' : 'no-line']" class="line"></p>
            </div>
        </div>
        <div class="content-user">
            <!-- 账号注册 -->
            <el-form v-show="wayID == 1" :model="ruleFormAccount" :rules="rulesAccount" ref="ruleFormAccount"
                class="phone-box">
                <el-form-item prop="userName">
                    <el-input class="my-input" v-model="ruleFormAccount.userName" placeholder="请输入注册账号"></el-input>
                </el-form-item>
                <el-form-item prop="phoneNum">
                    <el-input class="my-input" v-model="ruleFormAccount.phoneNum" placeholder="+86 | 手机号"></el-input>
                </el-form-item>
                <el-form-item prop="password">
                    <el-input type="password" class="my-input" v-model="ruleFormAccount.password"
                        placeholder="输入密码"></el-input>
                </el-form-item>
                <el-form-item prop="checkPass">
                    <el-input type="password" class="my-input" v-model="ruleFormAccount.checkPass"
                        placeholder="再次输入密码"></el-input>
                </el-form-item>
                <el-form-item prop="inputVerifyCode">
                    <el-input class="my-input" v-model="ruleFormAccount.inputVerifyCode" placeholder="请输入短信验证码"></el-input>
                    <span :id="ruleFormAccount.innerSpanId" @click="reqVerifyCode('ruleFormAccount')" class="get-code">{{
                        ruleFormAccount.textReq }}</span>
                </el-form-item>
                <div class="submit">
                    <el-button type="primary" @click="submitRegister('ruleFormAccount')">注册</el-button>
                </div>
            </el-form>
            <!-- 短信注册 -->
            <el-form v-show="wayID == 2" :model="ruleFormSMS" :rules="rulesSMS" ref="ruleFormSMS" class="phone-box">
                <el-form-item prop="phoneNum">
                    <el-input class="my-input" v-model="ruleFormSMS.phoneNum" placeholder="+86 | 手机号"></el-input>
                </el-form-item>
                <el-form-item prop="inputVerifyCode">
                    <el-input class="my-input" v-model="ruleFormSMS.inputVerifyCode" placeholder="请输入短信验证码"></el-input>
                    <span :id="ruleFormSMS.innerSpanId" @click="reqVerifyCode('ruleFormSMS')" class="get-code">{{
                        ruleFormSMS.textReq }}</span>
                </el-form-item>
                <!-- <span class="login-tip">未注册手机号验证通过后将自动注册</span> -->
                <div class="submit">
                    <el-button type="primary" @click="submitRegister('ruleFormSMS')">注册</el-button>
                </div>
            </el-form>
            <div class="agreement">
                <el-radio-group v-model="radioValue">
                    <el-radio label=""></el-radio><!-- 勾选时，radioValue取值为""。 -->
                </el-radio-group>
                <span class="text">我已阅读并同意</span>&nbsp;
                <span @click="localOpenAgreement">服务条款</span><span> 、</span><span @click="localOpenPrivacy">法律声明和隐私政策</span>
            </div>
            <div @click="goLoginFun" class="go_login">已有账号去登录</div>
            <div class="about">
                <span @click="localOpenFeedback">用户反馈</span> | <span @click="localOpenAboutUs">关于我们</span>
            </div>
        </div>
    </div>
</template>

<script>
import { net } from "@/utils/net.js";
import { netSessionBorn } from "@/utils/netSessionBorn.js";
import { verifyCodeMATCHOK, doLoginSuccessIdStrUAC, urlPrefix } from "@/utils/common.js";
import { openAgreement, openPrivacy, openAboutUs, openFeedback } from "@/utils/common.js";

export default {
    name: 'register',
    data() {
        let validatePhone = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('请输入手机号'));
                return;
            } else {
                let reg = /^(?:(?:\+|00)86)?1(?:(?:3[\d])|(?:4[5-79])|(?:5[0-35-9])|(?:6[5-7])|(?:7[0-8])|(?:8[\d])|(?:9[1589]))\d{8}$/
                if (!reg.test(value)) {
                    callback(new Error('请输入正确的手机号'));
                    return;
                }
                callback();
                return;
            }
        };
        let validateVerifyCode = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('请输入短信校验码'));
                return;
            } else {
                if (this.cachedLocalVerifyCodesSet.has(value.trim())) {
                    callback(new Error("每条短信校验码只能校验1次：\"" + value + "\" 。感谢您的谅解和支持。"));
                    return;
                }
                let regexVerifyCodeNumber = /^\d{4,6}$/;
                if (!regexVerifyCodeNumber.test(value)) {
                    callback(new Error('请输入正确的短信校验码'));
                    return;
                }
                callback();// callback()的真实功能是，让validate调用链得以持续进行，尤其是，完成后回到caller代码。
            }
        };
        let validateAccount = (rule, account, callback) => {
            if (account === '') {
                callback(new Error('请输入注册账号'));
                return;
            } else {
                // 是否已经存在的账号（userName）：查询页面Map，或者发起异步JS URL请求。
                const existed = this.isExistedAccount(account);
                if (existed != null && existed) {
                    callback(new Error('该账号已经注册在先'));
                    return;
                } else
                    callback();// callback()的真实功能是，让validate调用链得以持续进行，尤其是，完成后回到caller代码。
            }
        };
        let validatePass = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('请输入密码'));
            } else {
                if (this.ruleFormAccount.checkPass !== '') {
                    this.$refs.ruleFormAccount.validateField('checkPass');
                }
                callback();
            }
        };
        let validatePass2 = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('请再次输入密码'));
            } else if (value !== this.ruleFormAccount.password) {
                callback(new Error('两次输入密码不一致!'));
            } else {
                callback();
            }
        };
        // { required: true, message: '请输入注册账号', trigger: 'blur' }
        return {
            // radioValue: "",// 默认勾选了radio：服务条款和隐私政策。
            radioValue: "1",// 默认没有勾选radio：服务条款和隐私政策。
            wayID: 1, //登录方式，1-手机号登录,2-账户密码登录
            rulesAccount: {
                phoneNum: [
                    { validator: validatePhone, trigger: 'blur' },
                ],
                inputVerifyCode: [
                    { validator: validateVerifyCode, trigger: 'blur' },
                ],
                userName: [
                    { validator: validateAccount, trigger: 'blur' },
                ],
                password: [
                    { validator: validatePass, trigger: 'blur' },
                ],
                checkPass: [
                    { validator: validatePass2, trigger: 'blur' },
                ],
            },
            rulesSMS: {
                phoneNum: [
                    { validator: validatePhone, trigger: 'blur' },
                ],
                inputVerifyCode: [
                    { validator: validateVerifyCode, trigger: 'blur' }
                ],
            },
            // 验证码模板类型。
            // 统一的字符串：来自com.medwords.aliyun_sms.AliyunSMSConfiguration，以及HttpRequest。
            SMS_VERIFY_TYPE_REGISTER: "verify_code_regi",// 注册验证码
            SMS_VERIFY_TYPE_LOGIN: "verify_code_login",// 登录验证码
            SMS_VERIFY_TYPE_RESET_PASSWORDS: "verify_code_reset", // 重置密码验证码
            // 结合"获取验证码"不可用时间倒计时长度，对抗NoStrIdSessionStr之前的爬虫攻击的逻辑就很清晰了：	
            // pre_session_str所标记的pre_session_id ###不可能超出### 不可用时间长度来连续请求验证码。比如2条/分钟。
            countDownValue: 60,// "获取验证码"不可用时间倒计时长度。
            ruleFormAccount: {
                phoneNum: '',//用户输入的手机号码
                inputVerifyCode: '',// 用户输入的短信验证码
                verifyCodeType: "verify_code_regi",
                textReq: "获取验证码",
                innerSpanId: "ruleFormAccount_span_id",// 设置id来获取<span>并改变其显示的" 59 s 后才能再次获取验证码"。
                localAvailable: true,// VUE JS端能否再次点击<span>请求获取验证码
                userName: '', //登录名
                password: '',
                checkPass: '',
                countDown: 60,
                intervalHandle: null,
                serverAvailable: false,// 服务器端是否已经完成了verifyCode的存储，两端同步。
            },
            ruleFormSMS: {
                phoneNum: '',//用户输入的手机号码
                inputVerifyCode: '',// 用户输入的短信验证码
                verifyCodeType: "verify_code_regi",
                textReq: "获取验证码",
                innerSpanId: "ruleFormSMS_span_id",// 设置id来获取<span>并改变其显示的" 59 s 后才能再次获取验证码"。
                localAvailable: true,// VUE JS端能否再次点击<span>请求获取验证码
                countDown: 60,
                intervalHandle: null,
                serverAvailable: false,// 服务器端是否已经完成了verifyCode的存储，两端同步。
            },
            intervalFormObj: null,// 文字显示倒计时60s：通过本ViewObj this的“全局引用”来取到数据对象Obj。

            // 对手机号码，不做“是否已经存在”的浏览器端验证：本来就要经历短信校验码。其账号业务逻辑交由服务器端完成。
            // cachedPhoneNumberMap: new Map(),
            // 将本地已经上行的短信验证码缓存在本地：服务器SMS下发给手机的短信验证码都只能上行检验1次。
            // 所有短信验证码用例，VUE端，都使用统一的js规范：verifyCodeMATCHOK()
            // 结合reset field，维护UI状态。
            cachedLocalVerifyCodesSet: new Set(),
            cachedAccountsMap: new Map(),
        }
    },
    methods: {
        changWay(id) {
            this.wayID = id
            if (id == 2) {
                this.$refs['ruleFormAccount'].resetFields();// 重置各个fields的值，以及清除之前出现的错误提示。
                return
            }
            this.$refs['ruleFormSMS'].resetFields();// 重置各个fields的值，以及清除之前出现的错误提示。
        },
        // 是否已经存在的账号：查询页面Map，或者发起异步JS URL请求 -- 返回后set this.cachedAccountsMap。并启动validate。
        isExistedAccount(userName) {
            if (userName == null || userName.trim() == "")
                return;
            // 查询页面Map
            const existed = this.cachedAccountsMap.get(userName);
            if (existed == null) {
                // 如果userName尚不存在缓存，那么发起异步JS URL请求，查询该账号是否已经注册。
                var url = urlPrefix + "/isExistedAccount.mesh";
                var localParams = new Array(this, "reactReqExistedAccount", userName, "&t=A");
                new net.ContentLoader(url, this.reactNetJsCallBack, null, "POST",
                    "userName=" + userName + "&t=A", localParams);// "&t=A"："type=Account"。
            }
            // existed = null/true/false。
            return existed;
        },
        reactReqExistedAccount(netJsObj, localParams) {
            const registerViewObj = localParams[0];
            const upAccount = localParams[2];
            const upType = localParams[3];// "&t=A"

            var jsonObj = null;
            try {
                jsonObj = JSON.parse(netJsObj.req.responseText);
            } catch (error) {
                console.error("*** reactReqExistedAccount() -- error from JSON.parse(netJsObj.req.responseText)", error.message);
            }
            // console.debug("JSON.parse(netJsObj.req.responseText)执行完毕！");

            //通用的 - 统一的js规范：在if (jsonObj.ok == 'OK')之后的第一句：（是否）跳转到login page。
            // 注册用例不使用上述统一的js规范。
            // if (switchToLoginPage(jsonObj)) return;

            if (netJsObj.req && jsonObj != null && jsonObj.ok == "OK") {
                // 根据下行数据，缓存和设置。
                const downAccount = jsonObj.account;// VUE端要求"account"而不是"userName"。
                if (downAccount == null || upAccount != downAccount)
                    return;
                // const downType = jsonObj.type;
                // if (downType == null || upType != downType)
                //     return;
                const downExisted = jsonObj.isExistedAccount;// VUE端要求"isExistedAccount"而不是"isExistedUserName"。
                if (downExisted == null)
                    return;

                // 缓存Map。
                this.cachedAccountsMap.set(downAccount, downExisted == "true");
                // 启动validate。
                this.$refs['ruleFormAccount'].validateField(['userName'], (validationMsg) => {
                    // 如果验证手机号码不通过，validateField()返回错误信息字符串，其length > 0；
                    // 如果验证手机号码通过了，validateField()返回字符串，其length == 0。
                    // alert("this.$refs['ruleFormAccount'].validateField(['userName'] 返回 " + validationMsg);
                    // alert("validOrErrorMsg.length = " + validationMsg.length);
                });
            }
        },
        reqVerifyCode(formName) {
            // alert('reqVerifyCode(' + formName + ')');
            // validateField()方法能够只检查'phoneNum'，而不是整个form。
            var isPhoneNumOK = true;
            this.$refs[formName].validateField(['phoneNum'], (validationMsg) => {
                // 如果验证手机号码不通过，validateField()返回错误信息字符串，其length > 0；
                // 如果验证手机号码通过了，validateField()返回字符串，其length == 0。
                // alert("validateField(['phoneNum'] 返回 " + validationMsg);
                // alert("validOrErrorMsg.length = " + validationMsg.length);

                isPhoneNumOK = validationMsg == null ? false : validationMsg.length <= 0;
            });
            if (!isPhoneNumOK) {
                // alert("验证手机号码没有通过。isPhoneNumOK = " + isPhoneNumOK);
                return;
            }

            // alert("reqVerifyCode()继续执行！");
            let thisRuleFormObj = null;
            if (formName == "ruleFormSMS" && this.ruleFormSMS.localAvailable) {
                thisRuleFormObj = this.ruleFormSMS;
            } else if (formName == "ruleFormAccount" && this.ruleFormAccount.localAvailable) {
                thisRuleFormObj = this.ruleFormAccount;
            }
            if (thisRuleFormObj == null) return;// 比如localAvailable = false; 不再能够请求验证码，直至60s之后。

            // 上行参数
            const phoneNumber = thisRuleFormObj.phoneNum;
            const verifyCodeType = thisRuleFormObj.verifyCodeType;
            if (verifyCodeType == null || verifyCodeType.trim == "")
                return;

            // 文字显示倒计时60s：通过本ViewObj this的“全局引用”来取到数据对象Obj。
            this.intervalFormObj = thisRuleFormObj;
            thisRuleFormObj.intervalHandle =
                window.setInterval(this.showCountdownText, 1000);

            var url = urlPrefix + "/reqVerifyCode.mesh";
            var localParams = new Array(this, "reactReqVerifyCode", phoneNumber, verifyCodeType, thisRuleFormObj); //本组件实例this能够传递给回调函数。
            new net.ContentLoader(url, this.reactNetJsCallBack, null, "POST",
                "phoneNumber=" + phoneNumber + "&verifyCodeType=" + verifyCodeType, localParams);

        },
        reactReqVerifyCode(netJsObj, localParams) {
            // 如果要再次提交URL Request，届时要求传入本组件实例registerViewObj（而不是netJsObj实例"this"）。
            const registerViewObj = localParams[0];
            const upPhoneNumber = localParams[2];
            const upVerifyCodeType = localParams[3];
            const ruleFormObj = localParams[4];

            var jsonObj = null;
            try {
                jsonObj = JSON.parse(netJsObj.req.responseText);
            } catch (error) {
                console.error("*** reactReqVerifyCode() -- error from JSON.parse(netJsObj.req.responseText)", error.message);
            }
            // console.debug("JSON.parse(netJsObj.req.responseText)执行完毕！");

            //通用的 - 统一的js规范：在if (jsonObj.ok == 'OK')之后的第一句：（是否）跳转到login page。
            // 注册用例不使用上述统一的js规范。
            // if (switchToLoginPage(jsonObj)) return;

            if (netJsObj.req && jsonObj != null && jsonObj.ok == "OK") {
                const downPhoneNumber = jsonObj.phoneNumber;
                const downVerifyCodeType = jsonObj.verifyCodeType;
                if (upPhoneNumber != downPhoneNumber || upVerifyCodeType != downVerifyCodeType)
                    return;
                // 根据下行信号，在VUE JS端保证形成同步：
                // 确认服务器完成短信验证码存储后，后续VUE JS才再度上行，完成对照处理。
                ruleFormObj.serverAvailable = true;
            }
        },
        // 注册提交
        submitRegister(formName) {
            var localValidation = true;
            // 整个form的检验：包括各个<el-form-item>，比如手机号码和校验码，账号（是否已经存在），以及密码和重复密码。
            this.$refs[formName].validate((valid) => {
                // 回调函数中的return;并不会让主线程执行return；所以由localValidation赋值带回状态到主线程。
                // if (!valid)
                //     return;
                localValidation = valid;
            });
            if (!localValidation)
                return;

            let thisRuleFormObj = null;
            if (formName == "ruleFormSMS" && this.ruleFormSMS.serverAvailable) {
                thisRuleFormObj = this.ruleFormSMS;
            } else if (formName == "ruleFormAccount" && this.ruleFormAccount.serverAvailable) {
                thisRuleFormObj = this.ruleFormAccount;
            }
            if (thisRuleFormObj == null) {
                alert("正在等待MeSH Proper服务器端回复该验证码，请稍后再次尝试。给您带来不便，敬请谅解。谢谢！");
                return;
            }

            // alert("this.radioValue = " + this.radioValue);
            if (this.radioValue != "") {
                alert("请您阅读并同意勾选服务条款和隐私政策。");
                return;
            }

            // 不再能够提交注册，直至下一次短信验证码请求并在服务器端完成存储之后。
            thisRuleFormObj.serverAvailable = false;

            // 上行参数
            const phoneNumber = thisRuleFormObj.phoneNum;
            const inputVerifyCode = thisRuleFormObj.inputVerifyCode;
            const verifyCodeType = thisRuleFormObj.verifyCodeType;
            var url = urlPrefix + "/doRegister.mesh";

            if (formName == "ruleFormSMS") {
                var localParams = new Array(this, "reactDoRegister",
                    phoneNumber, verifyCodeType, inputVerifyCode, null, null, thisRuleFormObj);
                new netSessionBorn.ContentLoader(url, this.reactNetJsCallBack, null, "POST",
                    "phoneNumber=" + phoneNumber + "&verifyCodeType=" + verifyCodeType +
                    "&verifyCode=" + inputVerifyCode + "&regiType=regi_phoneNumber", localParams);
                // 提交后，本次短信校验码清零。
                thisRuleFormObj.inputVerifyCode = "";
            } else if (formName == "ruleFormAccount") {
                // 上行参数
                const userName = thisRuleFormObj.userName;
                if (userName == null || userName.trim() == "") {
                    alert("请检查输入的注册账号。给您带来不便，敬请谅解。谢谢。");
                    return;
                }
                var password = thisRuleFormObj.password;
                const checkPass = thisRuleFormObj.checkPass;
                if (password == null || checkPass == null || password.trim() == "" || password != checkPass) {
                    alert("请检查2次输入的密码。给您带来不便，敬请谅解。谢谢。");
                    return;
                }
                password = encodeURIComponent(password);

                var localParams = new Array(this, "reactDoRegister",
                    phoneNumber, verifyCodeType, inputVerifyCode, userName, password, thisRuleFormObj);
                new netSessionBorn.ContentLoader(url, this.reactNetJsCallBack, null, "POST",
                    "phoneNumber=" + phoneNumber + "&verifyCodeType=" + verifyCodeType +
                    "&verifyCode=" + inputVerifyCode + "&regiType=regi_name" + "&userName=" +
                    userName + "&password=" + password, localParams);
            }
        },
        reactDoRegister(netJsObj, localParams) {
            const registerViewObj = localParams[0];
            const upPhoneNumber = localParams[2];
            const upVerifyCodeType = localParams[3];
            const upVerifyCode = localParams[4];
            const upUserName = localParams[5];
            const upPassword = localParams[6];
            const ruleFormObj = localParams[7];

            var jsonObj = null;
            try {
                jsonObj = JSON.parse(netJsObj.req.responseText);
            } catch (error) {
                console.error("*** reactDoRegister() -- error from JSON.parse(netJsObj.req.responseText)", error.message);
            }
            // console.debug("JSON.parse(netJsObj.req.responseText)执行完毕！");

            //通用的 - 统一的js规范：在if (jsonObj.ok == 'OK')之后的第一句：（是否）跳转到login page。
            // 注册用例不使用上述统一的js规范。
            // if (switchToLoginPage(jsonObj)) return;

            // 所有短信验证码用例，VUE端，都使用统一的js规范：verifyCodeMATCHOK()
            if (!verifyCodeMATCHOK(jsonObj, upVerifyCode, this.cachedLocalVerifyCodesSet)) {
                ruleFormObj.inputVerifyCode = "";
                return;
            }

            if (netJsObj.req && jsonObj != null && jsonObj.ok == "OK") {
                // 	* ["reg_false", "N/A", "N/A"] 返回register == null：注册失败，原因不详，状态存疑 -- 比如说爬虫攻击。
                //  * ["reg_false", "ERROR_MSG", "N/A"]：返回错误信息，比如说“该注册账号已经存在”，等。
                //  * ["login_ok"]：注册成功，web layer负责forward到regiType对应的loginType，最后返回相应的JSON - "login_ok"
                if (jsonObj.reg_false != null && jsonObj.reg_false == 'FALSE') {
                    if (jsonObj.ERROR_MSG != null) {
                        const errorMessage = decodeURIComponent(jsonObj.ERROR_MSG); // URLEncoder.encode("已经存在的用户账号", "utf-8"); 
                        alert(errorMessage);
                        // 缓存Map。
                        this.cachedAccountsMap.set(upUserName, true);
                        // 启动validate：因为缓存了(upUerName, true)，所以会显示错误信息。
                        this.$refs['ruleFormAccount'].validateField(['userName'], (validationMsg) => {
                            // 如果验证手机号码不通过，validateField()返回错误信息字符串，其length > 0；
                            // 如果验证手机号码通过了，validateField()返回字符串，其length == 0。
                            // alert("this.$refs['ruleFormAccount'].validateField(['userName'] 返回 " + validationMsg);
                            // alert("validOrErrorMsg.length = " + validationMsg.length);
                        });
                    }
                } else if (jsonObj.reg_ok != null && jsonObj.reg_ok == 'TRUE') {
                    // “注册即登录”：所以不会下发jsonObj.reg_ok
                } else if (jsonObj.login_ok == 'TRUE') {
                    // “注册即登录”：
                    // request.getRequestDispatcher("/doLogin.mesh").forward(request, response);
                    // 参考登录用例的：if (jsonObj.login_ok == 'TRUE') 
                    // alert("*** jsonObj.login_ok: " + jsonObj.userName + " Login SUCCESS !");
                    // alert("*** jsonObj.login_ok: pwd = " + jsonObj.pwd);
                    // 1、只在session_born用例，才下发single_sessionId_str字符串，存储在Window.localStorage。
                    // 2、到达搜索页面：urlPrefix + "/#/search"
                    doLoginSuccessIdStrUAC(jsonObj.pwd, jsonObj.uac);// 在MeSH Proper，登录即注册，注册即登录。
                }
            }
        },
        // 经过全局回调函数再次调用约定函数名的本地函数。
        reactNetJs(netJsObj) {
            var localParams = netJsObj.localObj;
            if (localParams[1] == "reactReqVerifyCode")
                // 短信校验码
                this.reactReqVerifyCode(netJsObj, localParams);
            else if (localParams[1] == "reactReqExistedAccount")
                // 查询Account是否存在
                this.reactReqExistedAccount(netJsObj, localParams);
            else if (localParams[1] == "reactDoRegister")
                // 注册用例
                this.reactDoRegister(netJsObj, localParams);
        },
        // 因为是在window.setInterval()中被调用，所以不能带有函数参数：
        // 所需参数（数据对象Obj）只能通过本ViewObj this的“全局引用”来取到。
        showCountdownText() {
            // console.debug("*** showCountdownText()正在执行……");
            // 通过本ViewObj this的“全局引用”来取到数据对象Obj。
            const crtRuleFormObj = this.intervalFormObj;
            //  禁用onclick响应
            crtRuleFormObj.localAvailable = false;// 不再能够请求验证码，直至60s之后。
            document.getElementById(crtRuleFormObj.innerSpanId).style.cursor = "text";

            if (crtRuleFormObj.countDown <= 0 || crtRuleFormObj.countDown > this.countDownValue)
                crtRuleFormObj.countDown = this.countDownValue;// 60

            crtRuleFormObj.countDown--;// 倒计时。
            crtRuleFormObj.textReq = crtRuleFormObj.countDown + " s 后才能再次获取验证码";// 更新倒计时文字。
            if (crtRuleFormObj.countDown == 0) {// 倒计时结束，并恢复localAvailable状态。
                window.clearInterval(crtRuleFormObj.intervalHandle);
                // 恢复localAvailable：恢复onclick响应。
                crtRuleFormObj.localAvailable = true;
                crtRuleFormObj.textReq = "获取验证码";
                document.getElementById(crtRuleFormObj.innerSpanId).style.cursor = "pointer";
            }
        },
        // 对手机号码，不做“是否已经存在”的浏览器端验证：本来就要经历短信校验码。其账号业务逻辑交由服务器端完成。
        // isExistedPhoneNumber(phoneNum) {
        //     const existed = cachedPhoneNumberMap.get(phoneNum);
        //     if (existed != null) {
        //         // 只做“已经注册”的提示，不做“尚未注册”的提示。
        //         if (existed == 1)
        //             alert(phoneNum + " 是已经注册的手机号码。");
        //     }
        //     else {
        //         // TODO

        //     }
        // },

        // 注册
        registerFun() {
            // console.debug("跳转到注册页面");
            this.$emit('onRegister')
        },
        // 微信支付
        WeChatLogin() {
            this.$emit('weChatLogin')
        },
        // 支付宝支付
        AlipayLogin() {
            this.$emit('alipayLogin')
        },
        // 忘记密码
        ForgetPass() {
            // console.debug("忘记了密码");
            this.$emit('forgetPass')
        },
        // 去登录
        goLoginFun() {
            this.$emit('jumpLogin')
        },
        localOpenAgreement() {
            openAgreement();
        },
        localOpenPrivacy() {
            openPrivacy();
        },
        localOpenAboutUs() {
            openAboutUs();
        },
        localOpenFeedback() {
            openFeedback();
        },
    }
}
</script>

<style lang="scss" scoped>
.main-login {
    // margin-top: 10px;
    width: 100%;

    .change-way {
        display: flex;
        align-items: center;
        // margin: 22px 36px 0px 36px;
        margin-top: 24px;
        width: 98%;

        .way {
            width: 188px;
            display: flex;
            flex-direction: column;
            align-items: center;
            cursor: pointer;

            .name-text {
                font-size: 1rem;
                font-weight: bold;
            }

            .line {
                // width: 100%;
                height: 3px;
            }

            .ok-change {
                color: #1CB0F6;
                margin-top: 0px;
                margin-bottom: 2px;
            }

            .no-change {
                color: #666666;
                margin-top: 0px;
                margin-bottom: 2px;
            }

            .ok-line {
                background-color: #1CB0F6;
                margin-top: 0px;
                margin-bottom: 0px;
                width: 81%;
            }

            .no-line {
                background-color: #FFFFFF;
                margin-top: 0px;
                margin-bottom: 0px;
            }
        }
    }

    .content-user {
        display: flex;
        flex-direction: column;
        width: 92%;// 与HomeView.vue -- .user-login中的width: 92%; 保持一致。
        margin: 30px 0 0 16px; // 由上面的width和margin共同调整宽度和占比/位置。

        .phone-box {
            position: relative;

            .el-form-item {
                height: 36px;
                margin-bottom: 10px; // 错误提示语的空间。
            }

            .el-form-item is-error {
                height: 36px;
                margin-bottom: 10px; // 错误提示语的空间。

            }

            ::v-deep .el-form-item__content {
                line-height: 26px;
                height: 26px;

            }

            ::v-deep .el-form-item__error {
                font-size: 12px; // 错误提示语的字体大小。
                padding-top: 0px; // 错误提示语的padding-top。
            }

            .get-code {
                position: absolute;
                right: 9px;
                bottom: 0px;
                z-index: 99;
                color: #1CB0F6;
                cursor: pointer;
            }

            .login-tip {
                font-size: .8rem;
                color: #999999;
            }

            // :deep(.is-required){
            //     margin-bottom: 10px !important;
            // }

            .submit {
                display: flex;
                flex-direction: column;
                // margin-top: 36px;

                button {
                    margin-top: 20px;
                }

                :deep(.el-button+.el-button) {
                    margin-left: 0;
                }
            }
        }

        .agreement {
            display: flex;
            align-items: center;
            margin-top: 4px;

            span {
                font-size: 12px; //.4rem;
                font-weight: 900;
                color: #1CB0F6;
                cursor: pointer;
            }

            .text {
                color: #999999;
                cursor: text;
            }
        }

        .go_login {
            font-size: 12px; // 1rem;
            font-weight: 600;
            margin-top: 20px;
            color: #1CB0F6;
            cursor: pointer;
        }

        .about {
            width: 36%; // 否则100%，会占据整行。
            text-align: center;
            margin: 22px 56px 16px 108px; // 调整页面上位置。
            font-size: 11px; //.4rem;
            color: #999999;
            cursor: pointer;
        }

        .my-input {
            width: 100%;

            :deep(.el-input__inner) {
                background-color: #F6F6F6;
                height: 26px; // 输入框的高度：为错误提示语留出空间
                line-height: 26px; // 输入框的高度：为错误提示语留出空间
            }
        }
    }
}
</style>