<template>
    <div class="main-login">
        <div class="change-way">
            <div class="way" @click="changWay(1)">
                <span :class="[wayID == 1 ? 'ok-change' : 'no-change']" class="name-text">手机登录官网</span>
                <p :class="[wayID == 1 ? 'ok-line' : 'no-line']" class="line"></p>
            </div>
            <!-- <div class="way" @click="changWay(2)">
                <span :class="[wayID == 2 ? 'ok-change' : 'no-change']" class="name-text">密码登录</span>
                <p :class="[wayID == 2 ? 'ok-line' : 'no-line']" class="line"></p>
            </div> -->
        </div>
        <div class="content-user">
            <!-- 账号登录 -->
            <el-form v-show="wayID == 2" :model="ruleFormPass" :rules="rulesPass" ref="ruleFormPass" class="phone-box">
                <el-form-item prop="userName">
                    <el-input class="my-input" v-model="ruleFormPass.userName" placeholder="用户"></el-input>
                </el-form-item>
                <el-form-item prop="password">
                    <el-input type="password" class="my-input" v-model="ruleFormPass.password"
                        placeholder="密码"></el-input>
                </el-form-item>
                <span class="forget-pass" @click="ForgetPass">忘记密码？</span>
                <div class="submit">
                    <el-button type="primary" @click="submitLogin('ruleFormPass')">登录（ / 注册）</el-button>
                    <!-- <el-button type="primary" plain @click="registerFun">注册</el-button> -->
                </div>
            </el-form>
            <!-- 短信登录 -->
            <el-form v-show="wayID == 1" :model="ruleFormSMS" :rules="rulesSMS" ref="ruleFormSMS" class="phone-box">
                <el-form-item prop="phoneNum">
                    <!-- <el-input class="my-input" v-model="ruleFormSMS.phoneNum" placeholder="+86 | 手机号"></el-input> -->
                    <el-input disabled class="my-input" v-model="ruleFormSMS.phoneNum"
                        placeholder="请于页面左下区域 1 秒下载 PubMed 插件使用"></el-input>
                </el-form-item>
                <el-form-item prop="inputVerifyCode">
                    <!-- <el-input class="my-input" v-model="ruleFormSMS.inputVerifyCode" placeholder="请输入短信验证码"></el-input> -->
                    <!-- <span :id="ruleFormSMS.innerSpanId" @click="reqVerifyCode('ruleFormSMS')" class="get-code">{{
                ruleFormSMS.textReq }}</span> -->
                    <el-input disabled class="my-input" v-model="ruleFormSMS.inputVerifyCode"
                        placeholder="官网登录升级中，请 1 秒下载 PubMed 插件使用"></el-input>
                </el-form-item>
                <div class="submit">
                    <el-button disabled type="primary" @click="submitLogin('ruleFormSMS')">{{ ruleFormSMS.showLoginText
                        }}</el-button>
                    <!-- <el-button type="primary" @click="submitLogin('ruleFormSMS')">登录（即注册）</el-button> -->
                    <!-- <el-button type="primary" plain @click="registerFun">注册</el-button> -->
                </div>
            </el-form>
            <div class="agreement-mesh">
                <el-radio-group v-model="radioAgreement">
                    <el-radio label=""></el-radio><!-- 勾选时，radioAgreement取值为""。 -->
                </el-radio-group>
                <span class="text">我已阅读并同意</span>&nbsp;
                <span @click="localOpenAgreement">服务条款</span><span> 、</span><span
                    @click="localOpenPrivacy">法律声明和隐私政策</span>
            </div>
            <!-- 第三方登录是否要求用户手机短信验证码：不合理 -- 微信支付宝已经是身份验证ID了。 -->
            <!-- 勾选时，radioAccountBind取值为""。 -->
            <div class="other-login">
                <div class="agreement">
                    <el-radio-group disabled v-model="radioAccountBind">
                        <el-radio label=""></el-radio>
                    </el-radio-group>
                    <!-- <span class="text">暨</span>&nbsp;
                    <span @click="localOpenAccountBind">第三方账号绑定登录协议</span>&nbsp; -->
                    <span class="text-disabled">暨</span>&nbsp;
                    <span class="span-disabled">微信支付宝账号登录</span>&nbsp;
                </div>
                <!-- <img @click="WeChatLogin" src="../assets/weixin.png" alt="微信"> -->
                &nbsp;
                <!-- <img @click="AlipayLogin" src="../assets/zhifubao.png" alt="支付宝"> -->
            </div>
            <div class="about">
                <!-- <span @click="localOpenFeedback">用户反馈</span> | <span @click="localOpenAboutUs">关于我们</span> -->
                用户反馈 | <span @click="localOpenAboutUs">关于我们</span>
            </div>
        </div>
        <div align="center">
            <div id="divActionForm">
                <form id="actionForm" action="" method="post">
                    <input id="suffixNetJs" name="suffixNetJs" type="hidden" value="" />
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import { net } from '@/utils/net.js'
import { netSessionBorn } from "@/utils/netSessionBorn.js";
import { urlPrefix, doLoginSuccessIdStrUAC, verifyCodeMATCHOK, loadTotalUACAndIdStrs } from "@/utils/common.js"
import { openAgreement, openAccountBind, openPrivacy, openAboutUs, openFeedback } from "@/utils/common.js";

export default {
    name: 'login',
    data() {
        let validatePhone = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('请输入手机号'));
            } else {
                let reg = /^(?:(?:\+|00)86)?1(?:(?:3[\d])|(?:4[5-79])|(?:5[0-35-9])|(?:6[5-7])|(?:7[0-8])|(?:8[\d])|(?:9[1589]))\d{8}$/
                if (!reg.test(value)) {
                    callback(new Error('请输入正确的手机号'));
                    return
                }
                callback();
            }
        };
        let validateVerifyCode = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('请输入短信校验码'));
                return;
            } else {
                if (this.cachedLocalVerifyCodesSet.has(value.trim())) {
                    callback(new Error("每条短信校验码只能校验1次：\"" + value + "\" 。感谢您的谅解和支持。"));
                    return;
                }
                let regexVerifyCodeNumber = /^\d{4,6}$/;
                if (!regexVerifyCodeNumber.test(value)) {
                    callback(new Error('请输入正确的短信校验码'));
                    return;
                }
                callback();// callback()的真实功能是，让validate调用链得以持续进行，尤其是，完成后回到caller代码。
            }
        };
        let validateUserName = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('请输入用户名'));
            } else {
                let reg = /^[A-Za-z][A-Za-z0-9_]{5,31}$/
                if (!reg.test(value)) {// 比如"a12345678901234567890123456789bc"
                    callback(new Error('字母数字下划线（字母开头），长度6~32位。感谢您的谅解和支持。'));
                    return
                }
                callback();
            }
        };
        return {
            wayID: 1, //登录方式，1-手机号登录,2-账户密码登录
            radioAgreement: "",// 默认勾选了radio：服务条款、法律声明和隐私政策。
            // radioAgreement: "1",// 默认没有勾选radio：服务条款、法律声明和隐私政策。
            radioAccountBind: "",// 默认勾选了radio：第三方账号绑定登录协议。
            // radioAccountBind: "1",// 默认没有勾选radio：第三方账号绑定登录协议。
            rulesSMS: {
                phoneNum: [
                    { validator: validatePhone, trigger: 'blur' },
                ],
                inputVerifyCode: [
                    { validator: validateVerifyCode, trigger: 'blur' }
                ],
            },
            rulesPass: {
                userName: [
                    { validator: validateUserName, trigger: 'blur' },
                    // { required: true, message: '请输入用户名', trigger: 'blur' }
                ],
                password: [
                    { required: true, message: '请输入密码', trigger: 'blur' }
                ],
            },
            // 统一的字符串：来自com.medwords.aliyun_sms.AliyunSMSConfiguration，以及HttpRequest。
            SMS_VERIFY_TYPE_REGISTER: "verify_code_regi",// 注册验证码
            SMS_VERIFY_TYPE_LOGIN: "verify_code_login",// 登录验证码
            SMS_VERIFY_TYPE_RESET_PASSWORDS: "verify_code_reset", // 重置密码验证码
            // 结合"获取验证码"不可用时间倒计时长度，对抗NoStrIdSessionStr之前的爬虫攻击的逻辑就很清晰了：	
            // pre_session_str所标记的pre_session_id ###不可能超出### 不可用时间长度来连续请求验证码。比如2条/分钟。
            countDownValue: 60,// "获取验证码"不可用时间倒计时长度。
            ruleFormSMS: {
                phoneNum: '',
                inputVerifyCode: '',
                verifyCodeType: "verify_code_login",
                textReq: "获取验证码",
                innerSpanId: "ruleFormSMS_span_id",// 设置id来获取<span>并改变其显示的" 59 s 后才能再次获取验证码"。
                localAvailable: true,// VUE JS端能否再次点击<span>请求获取验证码
                countDown: 60,
                intervalHandle: null, // window.setInterval()的返回值赋值。
                serverAvailable: false,// 服务器端是否已经完成了verifyCode的存储，两端同步。
                showLoginText: "登录", // 默认显示"登录"字样。
            },
            ruleFormPass: {
                userName: '',
                password: '',
            },
            // 将本地已经上行的短信验证码缓存在本地：服务器SMS下发给手机的短信验证码都只能上行检验1次。
            // 所有短信验证码用例，VUE端，都使用统一的js规范：verifyCodeMATCHOK()
            // 结合reset field，维护UI状态。
            cachedLocalVerifyCodesSet: new Set(),
            intervalFormObj: null,// 文字显示倒计时60s：通过本ViewObj this的“全局引用”来取到数据对象Obj。

            totalUACAndIdStrs: null,
        }
    },
    mounted() {
        // console.debug("*** getReqSuffixMeSHJsURLStr()： " + getReqSuffixMeSHJsURLStr());
        this.totalUACAndIdStrs = loadTotalUACAndIdStrs();
    },
    methods: {
        changWay(id) {
            this.wayID = id
            if (id == 2) {
                this.$refs['ruleFormSMS'].resetFields();// 重置各个fields的值，以及清除之前出现的错误提示。
                return
            }
            this.$refs['ruleFormPass'].resetFields();// 重置各个fields的值，以及清除之前出现的错误提示。
        },
        reqVerifyCode(formName) {
            // alert('reqVerifyCode(' + formName + ')');
            // validateField()方法能够只检查'phoneNum'，而不是整个form。
            var isPhoneNumOK = true;
            this.$refs[formName].validateField(['phoneNum'], (validationMsg) => {
                // 如果验证手机号码不通过，validateField()返回错误信息字符串，其length > 0；
                // 如果验证手机号码通过了，validateField()返回字符串，其length == 0。
                // alert("validateField(['phoneNum'] 返回 " + validationMsg);
                // alert("validOrErrorMsg.length = " + validationMsg.length);

                isPhoneNumOK = validationMsg == null ? false : validationMsg.length <= 0;
            });
            if (!isPhoneNumOK) {
                // alert("验证手机号码没有通过。isPhoneNumOK = " + isPhoneNumOK);
                return;
            }

            // alert("reqVerifyCode()继续执行！");
            let thisRuleFormObj = null;
            if (formName == "ruleFormSMS" && this.ruleFormSMS.localAvailable) {
                thisRuleFormObj = this.ruleFormSMS;
            }
            if (thisRuleFormObj == null) return;// 比如localAvailable = false; 不再能够请求验证码，直至60s之后。

            // if (this.totalUACAndIdStrs != null && thisRuleFormObj.phoneNum != null) {
            //     for (const UACAndIdStrs in totalUACAndIdStrs) {
            //         // might phoneNum：可能是电话号码。如果存在密码注册和微信支付宝登录用例，那么也有可能是用户名/微信ID/支付宝ID。
            //         const uacMightPhoneNum = UACAndIdStrs[0].split("#")[0].trim();
            //         const idStr = UACAndIdStrs[1];
            //         // if (uacMightPhoneNum != null && uacMightPhoneNum == thisRuleFormObj.phoneNum) {
            //         //     break;
            //         // }
            //     }
            // }

            // 上行参数
            const phoneNumber = thisRuleFormObj.phoneNum;
            const verifyCodeType = thisRuleFormObj.verifyCodeType;
            if (verifyCodeType == null || verifyCodeType.trim == "")
                return;

            // 文字显示倒计时60s：通过本ViewObj this的“全局引用”来取到数据对象Obj。
            this.intervalFormObj = thisRuleFormObj;
            thisRuleFormObj.intervalHandle =
                window.setInterval(this.showCountdownText, 1000);

            var url = urlPrefix + "/reqVerifyCode.mesh";
            var localParams = new Array(this, "reactReqVerifyCode", phoneNumber, verifyCodeType, thisRuleFormObj); //本组件实例this能够传递给回调函数。
            new net.ContentLoader(url, this.reactNetJsCallBack, null, "POST",
                "phoneNumber=" + phoneNumber + "&verifyCodeType=" + verifyCodeType, localParams);

        },
        reactReqVerifyCode(netJsObj, localParams) {
            const userLoginViewObj = localParams[0];
            const upPhoneNumber = localParams[2];
            const upVerifyCodeType = localParams[3];
            const ruleFormObj = localParams[4];

            var jsonObj = null;
            try {
                jsonObj = JSON.parse(netJsObj.req.responseText);
            } catch (error) {
                console.error("*** reactReqVerifyCode() -- error from JSON.parse(netJsObj.req.responseText)", error.message);
            }
            // console.debug("JSON.parse(netJsObj.req.responseText)执行完毕！");

            //通用的 - 统一的js规范：在if (jsonObj.ok == 'OK')之后的第一句：（是否）跳转到login page。
            // 登录用例不使用上述统一的js规范。
            // if (switchToLoginPage(jsonObj)) return;

            if (netJsObj.req && jsonObj != null && jsonObj.ok == "OK") {
                const downPhoneNumber = jsonObj.phoneNumber;
                const downVerifyCodeType = jsonObj.verifyCodeType;
                if (upPhoneNumber != downPhoneNumber || upVerifyCodeType != downVerifyCodeType)
                    return;
                // 根据下行信号，在VUE JS端保证形成同步：
                // 确认服务器完成短信验证码存储后，后续VUE JS才再度上行，完成对照处理。
                ruleFormObj.serverAvailable = true;
            }
        },
        // 登录提交
        submitLogin(formName) {
            var localValidation = true;
            // 整个form的检验：包括各个<el-form-item>，比如手机号码和校验码，用户名以及密码。
            this.$refs[formName].validate((valid) => {
                // 回调函数中的return;并不会让主线程执行return；所以由localValidation赋值带回状态到主线程。
                // if (!valid)
                //     return;
                localValidation = valid;
            });
            if (!localValidation)
                return;

            let thisRuleFormObj = null;
            if (formName == "ruleFormSMS" && this.ruleFormSMS.serverAvailable) {
                thisRuleFormObj = this.ruleFormSMS;
                // 不再能够提交短信登录，直至下一次短信验证码请求并在服务器端完成存储之后。
                thisRuleFormObj.serverAvailable = false;
            } else if (formName == "ruleFormPass") {
                thisRuleFormObj = this.ruleFormPass;
            }
            if (thisRuleFormObj == null) {
                alert("正在等待MeSH Proper服务器端回复该验证码，请稍后再次尝试。给您带来不便，敬请谅解。谢谢！");
                return;
            }

            var url = urlPrefix + "/doLogin.mesh";
            // 上行参数
            if (formName == "ruleFormSMS") {
                const phoneNumber = thisRuleFormObj.phoneNum;
                const inputVerifyCode = thisRuleFormObj.inputVerifyCode;
                const verifyCodeType = thisRuleFormObj.verifyCodeType;
                const loginTypePhone = "login_phoneNumber";

                var localParams = new Array(this, "reactDoLogin", phoneNumber, verifyCodeType, inputVerifyCode, loginTypePhone, thisRuleFormObj);
                new netSessionBorn.ContentLoader(url, this.reactNetJsCallBack, null, "POST",
                    "phoneNumber=" + phoneNumber + "&verifyCodeType=" + verifyCodeType +
                    "&verifyCode=" + inputVerifyCode + "&loginType=" + loginTypePhone, localParams);
                // 提交后，本次短信校验码清零。
                thisRuleFormObj.inputVerifyCode = "";
            } else if (formName == "ruleFormPass") {
                // 上行参数
                const userName = thisRuleFormObj.userName;
                if (userName == null || userName.trim() == "") {
                    alert("请检查输入的用户名。");
                    return;
                }
                var password = thisRuleFormObj.password;
                if (password == null || password.trim() == "") {
                    alert("请检查输入的密码。给您带来不便，敬请谅解。谢谢。");
                    return;
                }
                password = encodeURIComponent(password);

                const loginTypeName = "login_name";

                var localParams = new Array(this, "reactDoLogin", userName, password, null, loginTypeName, thisRuleFormObj);
                new netSessionBorn.ContentLoader(url, this.reactNetJsCallBack, null, "POST",
                    "loginType=" + loginTypeName + "&userName=" + userName + "&password=" + password, localParams);
            }
        },
        reactDoLogin(netJsObj, localParams) {
            // console.debug("reactNetJs()方法执行……netJsObj.req.responseText = \n", netJsObj.req.responseText);
            const localUserLoginViewObj = localParams[0];
            const localPhoneNumOrUserName = localParams[2];
            const localVerifyTypeOrPassword = localParams[3];
            const localUpVerifyOrNull = localParams[4];
            const upLoginType = localParams[5];
            const localRuleFormObj = localParams[6];

            var jsonObj = null;
            try {
                jsonObj = JSON.parse(netJsObj.req.responseText);
            } catch (error) {
                console.error('*** reactDoLogin() -- error from JSON.parse(netJsObj.req.responseText)', error.message);
            }
            // console.debug("JSON.parse(netJsObj.req.responseText)执行完毕！");

            if (localUpVerifyOrNull != null)
                // 所有短信验证码用例，VUE端，都使用统一的js规范：verifyCodeMATCHOK()
                if (!verifyCodeMATCHOK(jsonObj, localUpVerifyOrNull, this.cachedLocalVerifyCodesSet)) {
                    localRuleFormObj.inputVerifyCode = "";
                    return;
                }

            // { "login_ok", "userName", "loginType"/"loginErrorMsg"/"N/A", "pwd", "uac" };
            // { "TRUE", ***, "loginType", ***, *** };
            // { "FALSE", ***, "loginErrorMsg"/"N/A", ***, *** };
            if (netJsObj.req && jsonObj != null && jsonObj.ok != null && jsonObj.ok == 'OK') {
                //通用的 - 统一的js规范：在if (jsonObj.ok == 'OK')之后的第一句：（是否）跳转到login page。
                // 登录用例不使用上述统一的js规范。
                // if (switchToLoginPage(jsonObj)) return;
                if (jsonObj.login_ok == 'TRUE') {
                    const downLoginType = jsonObj.loginType;// "login_phoneNumber"/"login_name"/"login_weixinId"/"login_alipayId"
                    if (upLoginType != downLoginType)
                        return;
                    // alert("*** jsonObj.login_ok: " + jsonObj.userName + " Login SUCCESS !");
                    // alert("*** jsonObj.login_ok: pwd = " + jsonObj.pwd);
                    // 1、只在session_born用例，才下发single_sessionId_str字符串，存储在Window.localStorage。
                    // 2、到达搜索页面：urlPrefix + "/#/search"
                    doLoginSuccessIdStrUAC(jsonObj.pwd, jsonObj.uac);// 在MeSH Proper，登录即注册，注册即登录。
                } else if (jsonObj.login_ok == 'FALSE') {
                    if (jsonObj.loginErrorMsg != null) {
                        // LOGIN_ERROR_MSG_NONEXISTED_USER_OR_PASSWORD = "密码或者用户名不符";
                        // 在MeSH Proper，登录即注册，注册即登录：所以短信/微信/支付宝登录用例不会发生“用户不存在”。
                        // javascript解码下行的utf-8（编码）字符串：decodeURIComponent()。
                        const errorMsg = decodeURIComponent(jsonObj.loginErrorMsg);
                        // 如何能够使用<element>框架的validate()？目前的快捷方式是alert()。
                        alert(errorMsg);
                    }
                    // console.debug("*** jsonObj.FALSE: SORRY! Login just failed! Please try it again.");
                }
            }
        },
        // 经过全局回调函数再次调用约定函数名的本地函数。
        reactNetJs(netJsObj) {
            var localParams = netJsObj.localObj;
            if (localParams[1] == "reactReqVerifyCode")
                // 请求短信校验码
                this.reactReqVerifyCode(netJsObj, localParams);
            else if (localParams[1] == "reactDoLogin")
                // 登录react
                this.reactDoLogin(netJsObj, localParams);
        },
        // 因为是在window.setInterval()中被调用，所以不能带有函数参数：
        // 所需参数（数据对象Obj）只能通过本ViewObj this的“全局引用”来取到。
        showCountdownText() {
            // console.debug("*** showCountdownText()正在执行……");
            // 通过本ViewObj this的“全局引用”来取到数据对象Obj。
            const crtRuleFormObj = this.intervalFormObj;
            //  禁用onclick响应
            crtRuleFormObj.localAvailable = false;// 不再能够请求验证码，直至60s之后。
            document.getElementById(crtRuleFormObj.innerSpanId).style.cursor = "text";

            if (crtRuleFormObj.countDown <= 0 || crtRuleFormObj.countDown > this.countDownValue)
                crtRuleFormObj.countDown = this.countDownValue;// 60

            crtRuleFormObj.countDown--;// 倒计时。
            crtRuleFormObj.textReq = crtRuleFormObj.countDown + " s 后才能再次获取验证码";// 更新倒计时文字。
            if (crtRuleFormObj.countDown == 0) {// 倒计时结束，并恢复localAvailable状态。
                window.clearInterval(crtRuleFormObj.intervalHandle);
                // 恢复localAvailable：恢复onclick响应。
                crtRuleFormObj.localAvailable = true;
                crtRuleFormObj.textReq = "获取验证码";
                document.getElementById(crtRuleFormObj.innerSpanId).style.cursor = "pointer";
            }
        },
        // 注册
        registerFun() {
            // console.debug("跳转到注册页面");
            this.$emit('onRegister')
        },
        // 微信支付
        WeChatLogin() {
            this.$emit('weChatLogin')
        },
        // 支付宝支付
        AlipayLogin() {
            this.$emit('alipayLogin')
        },
        // 忘记密码
        ForgetPass() {
            // console.debug("忘记了密码");
            this.$emit('forgetPass')
        },
        localOpenAgreement() {
            openAgreement();
        },
        localOpenPrivacy() {
            openPrivacy();
        },
        localOpenAboutUs() {
            openAboutUs();
        },
        localOpenFeedback() {
            openFeedback();
        },
        localOpenAccountBind() {
            openAccountBind();
        }
    }
}


</script>

<style lang="scss" scoped>
.main-login {
    margin-top: 36px;
    width: 100%;

    .change-way {
        display: flex;
        align-items: center;
        width: 100%;
        margin-top: 6px;

        .way {
            width: 188px;
            display: flex;
            flex-direction: column;
            align-items: center;
            cursor: pointer;

            .name-text {
                font-size: 1rem;
                font-weight: bold;
            }

            .line {
                width: 100%;
                height: 3px;
            }

            .ok-change {
                color: #1CB0F6;
                margin-top: 0px;
                margin-bottom: 2px;
            }

            .no-change {
                color: #666666;
                margin-top: 0px;
                margin-bottom: 2px;
            }

            .ok-line {
                background-color: #1CB0F6;
                margin-top: 0px;
                margin-bottom: 0px;
            }

            .no-line {
                background-color: #FFFFFF;
                margin-top: 0px;
                margin-bottom: 0px;
            }
        }
    }

    .content-user {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-top: 46px;

        .phone-box {
            position: relative;

            .el-form-item {
                height: 36px;
                margin-bottom: 10px; // 错误提示语的空间。
            }

            .el-form-item is-error {
                height: 36px;
                margin-bottom: 10px; // 错误提示语的空间。

            }

            // ::v-deep的作用就是找到当前元素下所有与::v-deep后面class名字匹配的元素，并实现修改样式。
            ::v-deep .el-form-item__content {
                line-height: 26px;
                height: 26px;

            }

            // ::v-deep不需要逐级嵌套。
            ::v-deep .el-form-item__error {
                font-size: 12px; // 错误提示语的字体大小。
                padding-top: 0px; // 错误提示语的padding-top。
            }

            .get-code {
                position: absolute;
                right: 9px;
                bottom: 0px;
                z-index: 99;
                color: #1CB0F6;
                cursor: pointer;
            }

            .forget-pass {
                position: absolute;
                right: 0px;
                top: 110px;
                font-size: 12px; // .4rem;
                color: #1CB0F6; // #999999
                cursor: pointer;
            }

            .submit {
                display: flex;
                flex-direction: column;
                margin-top: 66px;

                button {
                    margin-top: 20px;
                }

                :deep(.el-button+.el-button) {
                    margin-left: 0;
                }
            }
        }

        .agreement-mesh {
            display: flex;
            align-items: center;
            margin-top: 36px;

            span {
                font-size: 12px; // .4rem;
                font-weight: 900;
                color: #1CB0F6;
                cursor: pointer;
            }

            .text {
                color: #999999;
                cursor: text;
            }
        }

        .other-login {
            display: flex;
            align-items: center;
            justify-content: left; // 两部分Agreement都要水平左对齐。
            // margin-top: 12px;

            span {
                font-size: .4rem;
                color: #999999;
                margin-right: 18px;
            }

            img {
                width: 29px;
                height: 29px;
                margin-right: 7px;
                cursor: pointer;
            }

            .agreement {
                display: flex;
                align-items: center;
                // margin-top: 16px;

                span {
                    font-size: 12px; // .4rem;
                    font-weight: 900;
                    color: #1CB0F6;
                    cursor: pointer;
                }

                .text-disabled {
                    font-size: 12px; // .4rem;
                    font-weight: 300;
                    color: #999999;
                    cursor: text;
                }

                .span-disabled {
                    font-size: 12px; // .4rem;
                    font-weight: 300;
                    color: #999999;
                    cursor: text;
                }

            }

            span {
                font-size: 12px; //.4rem;
                font-weight: 900;
                color: #999999;
                margin-right: 10px;
            }

            img {
                display: flex;
                width: 23px; //29px;
                height: 23px; //29px;
                // margin-right: 7px;
                cursor: pointer;
            }

        }

        .about {
            text-align: center;
            margin-top: 76px; // 100px;
            font-size: 12px; // .4rem;
            font-weight: 500;
            color: #999999;

            // 只让<span>区域呈现指针，否则指针横贯整行。
            span {
                cursor: pointer;
            }
        }

        .my-input {
            width: 100%;

            :deep(.el-input__inner) {
                background-color: #F6F6F6;
                height: 26px; // 输入框的高度：为错误提示语留出空间
                line-height: 26px; // 输入框的高度：为错误提示语留出空间
            }
        }
    }
}
</style>