import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

// 可拖拽组件
import VueDragResize from 'vue-drag-resize'
Vue.component('vue-drag-resize', VueDragResize)

Vue.use(ElementUI);
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

Vue.prototype.globalUrlPrefix = "https://www.meshproper.com";
// Vue.prototype.localHostPrefix = "http://localhost";


// 经过VUE/webpack编译后，reactNetJsCallBack将成为JS全局函数，在netJs过程中被调用。
Vue.prototype.reactNetJsCallBack = function () {
  this.localObj[0].reactNetJs(this);
};

// 经过VUE/webpack编译后，reactExtNetJsCallBack将成为JS全局函数，在netJs过程中被调用。
Vue.prototype.reactExtNetJsCallBack = function () {
  this.localObj[0].reactExtNetJs(this);
};

