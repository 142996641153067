<template>
    <div class="weixin-main">
        <img class="code-img" src="../assets/code.png" alt="二维码">
        <div class="weixin-text">
            <img src="../assets/weixin.png" alt="微信">
            <span>使用微信扫一扫登录</span>
        </div>
        <div class="go-login" @click="goLogin">
            <span>去登录</span>
            <img src="../assets/xiangyoujiantou.png">
        </div>
    </div>
</template>

<script>
export default {
    name: "weChat-login",
    data() {
        return {

        }
    },
    methods: {
        goLogin(){
            this.$emit('goLogin')
        }
    }
}
</script>

<style lang="scss" scoped>
.weixin-main {
    width: 376px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .code-img {
        width: 206px;
        height: 206px;
    }

    .weixin-text {
        display: flex;
        align-items: center;
        margin-top: 21px;

        img {
            width: 29px;
            height: 29px;
            margin-right: 14px;
        }

        span {
            font-size: .4rem;
            color: #999999;
        }
    }

    .go-login {
        display: flex;
        align-items: center;
        font-size: .4rem;
        color: #1CB0F6;
        margin-top: 44px;

        img {
            width: 12px;
            height: 12px;
            margin-left: 3px;
        }
    }
}
</style>