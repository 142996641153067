<template>
  <!-- :style="{ height: screenHeight + 'px' }" -->
  <div class="home-main">
    <!-- 头部文字 -->
    <div class="head-box">
      <img class="logo" src="../assets/logo(1).png" alt="PubMed 更快 更强 更像样">
      <div class="tip-box">
        <!-- <div class="tip-box_1">
          <img class="tip" src="../assets/laba.png" alt="提示">
          <span v-if="messageInfo_1.type == 1" class="veryImportant">{{ messageInfo_1.text }}</span>
          <span v-else-if="messageInfo_1.type == 2" class="importantText">{{ messageInfo_1.text }}</span>
          <span v-else class="justSoSo">{{ messageInfo_1.text }}</span>
        </div> -->
        <!-- <div class="tip-box_2">
          <img class="tip" src="../assets/laba.png" alt="提示">
          <span v-if="messageInfo_2.type == 1" class="veryImportant">{{ messageInfo_2.text }}</span>
          <span v-else-if="messageInfo_2.type == 2" class="importantText">{{ messageInfo_2.text }}</span>
          <span v-else class="justSoSo">{{ messageInfo_2.text }}</span>
        </div> -->
      </div>
    </div>

    <!-- 中间登录 -->
    <div class="center-box">
      <!-- 左边展示功能区域 -->
      <div class="left-content">
        <!-- <img class="bac-o" src="../assets/mengban.png"> -->
        <div class="content-box">
          <div class="title">
            <span>PubMed 更快 更强 更像样：机器计算数据插件</span>
            <!-- <span>PubMed 更快 更强 更像样：机器计算数据插件 <span class="assist">辅助</span></span> -->
            <!-- <span>机器计算数据插件 辅助 PubMed 文献场景</span> -->
            <div class="parting-line"></div>
          </div>
          <div class="text-1-catch">
            <!-- 一个右向小箭头。经反复考虑，初期突出品牌内容和亮点所以不予显示，认知成熟期突出品牌和定位，再予以显示：<img class="img-prefix" src="../assets/right.png"> -->
            <!-- <span>“恰当的”，“像样的”，“名副其实的”，“就应该是这样的”。</span> -->
            <span><span class="minute">1</span> 分钟 Catch 文献全文 Full-text，<span class="minute">5</span>
              分钟完成一次高质量的文献筛选</span>
          </div>
          <div class="text-candidate">
            <span><img src="../assets/1147.png" class="able-list">丰富的 搜索提示词</span><span style="margin-left: 12px;"><img
                src="../assets/1147.png" class="able-list">新的 精准文献搜索。</span>
          </div>
          <div class="text-tip">
            简单高效&nbsp;&nbsp;&nbsp;&nbsp;释放潜能&nbsp;&nbsp;&nbsp;&nbsp;就应该是这样的
          </div>
          <div class="text-remark">
            （ 辅助专业文献的阅读和训练 ）
          </div>
          <!-- <div class="full-text-box">
            <img src="../assets/ok.png" alt="ok">
            <span>一分钟Catch全文Full-text</span>
          </div>
          <div class="full-text-box">
            <img src="../assets/ok.png" alt="ok">
            <span>1次高质量筛选用时3-5分钟</span>
          </div> -->
          <!-- <div class="Proper">
            <span>MeSH Proper有什么？</span>
            <p class="parting-line2"></p>
          </div> -->
          <!-- 视频播放列表 -->
          <div class="video-box">
            <div class="video-left" @click="clickPlayVideo(0)">
              <!-- <img src="../assets/bofang.png" alt="播放"> -->
              <img src="../assets/MeSH Proper Home Page.png" alt="1 分钟Catch文献全文Full-text，5 分钟完成一次高质量文献筛选">
              <!-- <span>第45届乒乓球世锦赛冠军张莹莹</span> -->
            </div>
            <div class="video-right">
              <div class="video-content" v-for="item in videoList" :key="item.id" @click="clickPlayVideo(item.id)">
                <img src="../assets/MeSH Proper Home Page CH.png" alt="雄关漫道真如铁，而今迈步从头越 ！">
                <!-- <span>{{ item.title }}</span> -->
              </div>
            </div>
          </div>
          <!-- <div class="more">
            了解更多
            <img src="../assets/right.png">
          </div> -->
          <!-- <div class="bottom-box-1">
            <img src="../assets/1147.png">
            <span>聚焦您的业务，释放专业潜能，释放文献价值</span>
          </div> -->
          <!-- <span>文献中的医学业务真正成为可行</span> -->
          <!-- <span>聚焦医学业务，释放专业潜能，医学文献真正助力医学</span> -->
          <div class="bottom-box-2">
            <span>
              <div class="blue-edge"><span>该<span class="red-pubmed">PubMed 插件</span>来自 Microsoft Edge
                  官方应用商店（放心使用）：</span></div>
              <!-- <div class="operate-ext-mesh" @click="localEdgeOfficialMeSHProper">
                <span>如果您当前是 <span class="url-meshwindows-edge"> Windows Edge 浏览器</span>，</span>
                <span class="underline"><span class="red-click">点击</span>（1秒）加载</span>
                <img class="click" src="../assets/click.png"></img>
              </div> -->
              <div id="divCopyLink" class="copy-link-cursor" @click="copyLink">
                <div>
                  <span><span class="red-click">点击</span>复制 Edge 官方应用商店链接：</span>
                  <img class="click-hand" src="../assets/click.png"></img>
                </div>
              </div>
              <div @click="selectLink" @dblclick="selectLink" class="div-url-mesh">
                <input id="inputLinkId" type="text" readonly size="70" class="url-mesh"
                  :value=edgeExtensionLink></input>
              </div>
              <div class="operate-ext-mesh">
                <span class="windows-edge, red-click">按键</span> Windows<span style="margin: 0 6px 0 6px;"><img
                    class="windows-png" src="../assets/windows.png"></span>键，<span
                  class="windows-edge">输入"edge"</span>，<span class="ctrl-v">Ctrl+V </span>，<span class="blue-load">1
                  秒加载</span>
                <div class="enter-key">
                  <span style="margin-right: 34px;">（视窗）</span><span style="margin-right: 6px;">（回车）</span><span
                    style="margin-right: 10px;">（回车）</span><span><img src="../assets/btn-ext-mesh.png"
                      class="btn-ext-mesh"></span>
                </div>
              </div>
            </span>
          </div>
          <!-- <span>恰当的，像样的，名副其实的，就应该是这样的</span> -->
          <!-- <span>做您最檀长的，聚焦于医学业务，释放专业潜能</span> -->
          <!-- <span>“雄关漫道真如铁，而今迈步从头越” -- 《忆秦娥·娄山关》</span> -->
        </div>
      </div>
      <!-- 右边操作区 -->
      <div class="right-content">
        <userLogin v-if="type == 1" class="user-login" @weChatLogin="WeChatFun" @forgetPass="onForgetPass"
          @onRegister="onRegisterFun" @alipayLogin="alipayFun" />
        <WeChat v-else-if="type == 2" @goLogin="onUserLogin" />
        <Alipay v-else-if="type == 3" @goLoginAlipay="onLoginAlipay" />
        <RegisterView v-else-if="type == 4" @jumpLogin="jumpLoginFun" />
        <ForgotPass v-else-if="type == 5" @jumpToLogin="jumpLoginFun" />
      </div>
    </div>

    <!-- 底部文字 -->
    <div class="page-bottom-box">
      <span>成都拙然人工智能料技有限公司统一社会信用代码：91510100MA69H23X38&nbsp;成都高新区益州大道北段388号8栋11楼1109号18980873412</span>
      <span>&nbsp;&nbsp;<a href="https://beian.miit.gov.cn">蜀ICP备2021026123号-1</a>&nbsp;<a
          href="https://beian.miit.gov.cn">蜀ICP备2021026123号-2</a></span>
    </div>

    <!-- 视频弹出框 -->
    <div ref="element" class="dialog-box">
      <el-dialog id="dialog_id" :visible.sync="dialogVisible" width="75%" top="11.5vh" :show-close="false"
        @close="handleCloseVideoDialog">
        <video id="uniqueVideo" :src="videoUrl" autoplay :width="videoWidth" :height="videoHeight" :volume="0.5" loop
          controls="controls">
          您的浏览器不支持 video 标签。
        </video>
      </el-dialog>
    </div>

  </div>
</template>

<script>
import userLogin from '../components/userLogin.vue'
import WeChat from '../components/weChat.vue'
import Alipay from '../components/alipay.vue'
import ForgotPass from '../components/forgotPass.vue'
import RegisterView from '../components/registerView.vue'

import { net } from "@/utils/net.js";
import { edgeOfficialMeSHProper, urlPrefix, switchToLoginPage } from "@/utils/common.js";

export default {
  components: { userLogin, WeChat, Alipay, ForgotPass, RegisterView },
  data() {
    return {
      // videoUrl: "https://mesh-proper-open.oss-cn-shanghai.aliyuncs.com/MeSH%20Proper%20Introduction%202895.mp4?Expires=1708697996&OSSAccessKeyId=STS.NUfZqPWtM9aPGx26yqqw6n8Fa&Signature=U2weanaIkTR0LS1ww8xpZXAxkfg%3D&security-token=CAISgQN1q6Ft5B2yfSjIr5bTEcvkuqtsjqO7RV6D0nkkffgagf3tgzz2IHhMfXhgCO0at%2F8znWtS7%2FwelrZ2RoRFclTeatN99KNQ6Q6nVIPKsMGu9osfhYa%2BGG%2BVU4NAJBFnOreXdsjUX9vwQYit9EYqs3mTF1iLW1iiLufG6%2FpOZcgWWQ%2BVdCdPAMwsSCJptM4dMwm2V5OqKQW4rWfMK0BqtwFg83Mdj56y2cqB8BHToUTnw%2BsO3eTLL4OjctNnMeVLMZK81%2FckcbHagmwCskQXrfgv1fwUqWub5orFUgtU7g6GdvLO9cViNkp%2BfqU8XOwm7%2FH3jq9%2FofeB1daul0sUYrgMA3SPGNz4m5OVSuakLdZ8baz8I3nXl9eXO5%2FXy1p4PCtEbVoaK4p9dicrUU0WJ2uEevP9yjfjeRy%2BTqWJ6qYy3Kduwk%2FgldjwfADXGuTJgHtEY8RgNhhybkRKxxjodq4XbgpJfkhnH6ySVYhpawhTr6rjtgDJTSp63uzIYw4mhVG8GoABmJWYMt7MXxefV%2BiJzm%2FV%2FlYNC3xBSPI9oefTUCx3BamZU1iDl6H3Ew3Z3%2B8naTUlgH7hkE1gcFFqfymX%2FPXECiWCJGj8RULuwycIkidCe213p7a3nmaWKmUD0R%2BxXcKu7QopNVbhxttYJh08TWtHcaKVhIrNbBdOA5EyBJ0KUwkgAA%3D%3D",
      videoUrl: null,// 只有1个视频框<el-dialog>，所以只有相应的1个播放videoUrl。
      // dialogVisible设置为true后显示视频框<el-dialog>，然后其<video>组件就开始访问其:src="videoUrl"属性，播放来自OSS的视频。
      dialogVisible: false,

      videoURLUpdateInterval: 3 * 3600 * 1000,// 视频URL更新的间隔时长是3个小时：该数值的单位是毫秒。
      videoIntroductionName: "MeSH Proper Introduction V_1_PubMed.mp4",
      videoUrlIntroduction: null, // 下行的OSS URL，赋值videoUrl
      video5ChampionsName: "MeSH Proper 5-Champions.mp4",
      videoUrl5Champions: null, // 下行的OSS URL，赋值videoUrl

      urlKeyword: "openResrcName",//上行URL的关键字参数：请求有效签名的video URL。
      // downJsonName: "reqMeSHProperOpen_ok",//下行JSON的field name：取得video URL/"FALSE"。
      lastClickTimeOfIntro: null,// 上次点击请求Introduction video URL的时间
      lastClickTimeOf5Champions: null,// 上次点击请求5-Champions video URL的时间

      screenHeight: document.documentElement.clientHeight,
      timer: false,
      videoWidth: 934,
      videoHeight: 566,
      videoList: [
        {
          id: 1,
          title: "雄关漫道真如铁，而今迈步从头越！",
        },
        // {
        //   id: 2,
        //   title: "MeSH Proper 官网搜索700万篇PMC",
        // },
        // {
        //   id: 3,
        //   title: "PubMed MeSH实时分解",
        // },
        // {
        //   id: 4,
        //   title: "PubMed MeSH插件视频",
        // },
      ],

      type: 1,//登录或者注册方式，1-手机号/账号登录，2-微信登录，3-支付宝登录，4-注册，5-忘记密码
      messageInfo_1: {
        type: 1, //消息类型， 1-很重要，2-重要，3-一般消息
        text: "MeSH Proper 正处于 免费期。预计收费规格是 0.5元 / 天 或 9.9元 / 月。感谢您的理解和支持！",
      },
      messageInfo_2: {
        type: 2, //消息类型， 1-很重要，2-重要，3-一般消息
        text: "图书馆账号登录 Google Scholar 时的 插件 后续上线，届时同一个 MeSH Proper 账号 并不 增加收费。"
      },
      edgeExtensionLink: "https://microsoftedge.microsoft.com/addons/detail/kgginlgphekolbdkbiienaedlgkpjlce",
    }
  },
  watch: {
    screenHeight: {
      immediate: true,
      handler: function (newVal) {
        // 为了避免频繁触发resize函数导致页面卡顿，使用定时器
        if (!this.timer) {
          this.screenHeight = newVal;
          this.timer = true;
          let _this = this;
          setTimeout(() => {
            //在这里做有关浏览器变化时需要做的操作
            _this.timer = false;
          }, 400);
        }
        // if(newVal)
      }
    }
  },
  mounted() {
    const _this = this;
    // 画echars
    // 监听浏览器窗口变化
    window.onresize = function () {
      // console.debug("agag")
      return (() => {
        window.screenHeight = document.body.clientHeight;
        _this.screenHeight = window.screenHeight;
      })();
    };
  },
  methods: {
    // 点击播放视频
    clickPlayVideo(whichVideo) {
      console.debug("点击了播放视频", whichVideo);

      if (this.dialogVisible) return;

      // https://developer.mozilla.org/zh-CN/docs/Web/JavaScript/Reference/Global_Objects/Date/now
      const nowMilliseconds = Date.now();

      // 符合更新videoURL的条件，才赋值pathName。
      var pathName = null;
      if (whichVideo == 0) {// introduction video
        if (this.lastClickTimeOfIntro == null
          || (nowMilliseconds - this.lastClickTimeOfIntro) > this.videoURLUpdateInterval)
          pathName = this.videoIntroductionName;
      } else if (whichVideo == 1) {// 5-Champions video
        if (this.lastClickTimeOf5Champions == null
          || (nowMilliseconds - this.lastClickTimeOf5Champions) > this.videoURLUpdateInterval)
          pathName = this.video5ChampionsName;
      }

      if (pathName == null) {
        // console.log("不需要更新videoURL，直接播放。");
        // 不需要更新videoURL，直接播放。
        this.doPlayVideo(whichVideo, false);
        return;
      }

      // 需要更新videoURL
      const url = urlPrefix + "/reqMeSHProperOpen.mesh";

      var localParams = new Array(this, "reactPlayVideo", whichVideo); //本组件实例this能够传递给回调函数。
      new net.ContentLoader(url, this.reactNetJsCallBack, null, "POST",
        this.urlKeyword + "=" + pathName, localParams);
    },
    reactPlayVideo(netJsObj, localParams) {
      const homeViewObj = localParams[0];
      const whichVideo = localParams[2];

      var jsonObj = null;
      try {
        jsonObj = JSON.parse(netJsObj.req.responseText);
      } catch (error) {
        console.error("*** reactPlayVideo() -- error from JSON.parse(netJsObj.req.responseText)", error.message);
      }
      // console.debug("JSON.parse(netJsObj.req.responseText)执行完毕！");

      //通用的 - 统一的js规范：在if (jsonObj.ok == 'OK')之后的第一句：（是否）跳转到login page。
      if (switchToLoginPage(jsonObj)) return;

      if (netJsObj.req && jsonObj != null && jsonObj.ok == "OK") {
        const downVideoURL = jsonObj.reqMeSHProperOpen_ok;
        if (downVideoURL == null || downVideoURL == "FALSE") {
          alert("本次视频播放失效。很抱歉带来不便，请稍后重试。感谢您的理解和支持！");
          return;
        }
        // 更新this.videoUrl：来自OSS的视频URL。
        if (whichVideo == null)
          return;
        else if (whichVideo == 0) {
          this.videoUrlIntroduction = downVideoURL;
        } else if (whichVideo == 1) {
          this.videoUrl5Champions = downVideoURL;
        }

        this.doPlayVideo(whichVideo, true);
      }
    },
    doPlayVideo(whichVideo, isRequestURL) {// isRequestURL就是经过OSS请求的新URL：需要更新last click time。
      this.videoUrl = null;

      // 赋值videoUrl
      if (whichVideo == 0 && this.videoUrlIntroduction != null) {
        this.videoUrl = this.videoUrlIntroduction;
        // 更新last click time：*小时内videoURL都有效，不至于反复请求videoURL。
        if (isRequestURL)// isRequestURL就是经过OSS请求的新URL：需要更新last click time。
          this.lastClickTimeOfIntro = Date.now();
      } else if (whichVideo == 1 && this.videoUrl5Champions != null) {
        this.videoUrl = this.videoUrl5Champions;
        // 更新last click time：*小时内videoURL都有效，不至于反复请求videoURL。
        if (isRequestURL)
          this.lastClickTimeOf5Champions = Date.now();
      }

      if (this.videoUrl == null) return;
      // console.log(this.videoUrl);

      // 从视频弹出框<div>取得基础数据width。
      let width = this.$refs.element.clientWidth;
      this.videoWidth = width
      // this.videoHeight = width * Number(556 / 966).toFixed(2)
      this.videoHeight = width * Number(1080 / 1920).toFixed(2)

      // dialogVisible设置为true后显示视频框<el-dialog>，然后其<video>组件就开始访问其:src="videoUrl"属性，播放来自OSS的视频。
      this.dialogVisible = true;// 播放视频。
      // console.log("元素宽高", width, this.videoWidth, this.videoHeight);
    },
    handleCloseVideoDialog() {
      this.dialogVisible = false;
      this.videoUrl = null;

      // 在关闭<el-dialog>后，domVideo仍然在播放视频（包括循环播放）。
      // 所以这里采用removeChild()/appendChild()的DOM操作，能够关闭视频，和再次加载<video>标签，准备下一次视频播放。
      const domVideo = document.getElementById("uniqueVideo");
      const domDialog = domVideo.parentNode;
      if (domVideo != null) {
        domDialog.removeChild(domVideo);
        // console.log("已经removeChild(domVideo);");
        domVideo.src = null;
        domDialog.appendChild(domVideo);
        // console.log("appendChild(domVideo);");
      }
    },
    localEdgeOfficialMeSHProper() {
      edgeOfficialMeSHProper();
    },
    copyLink() {
      navigator.clipboard.writeText(this.edgeExtensionLink).then(
        function () {
          /* clipboard successfully set */
          alert("该链接已复制。\n\n请按键 Windows 视窗键，输入\"edge\"，Ctrl+V，1 秒加载。");
        },
        function () {
          /* clipboard write failed */
          const divCopyLink = document.getElementById("divCopyLink");
          if (divCopyLink != null)
            divCopyLink.click = function () { };
          alert("您的浏览器不支持复制操作，辛苦您手动选中该链接文本后复制，然后请您按键 Windows 视窗键，输入\"edge\"，Ctrl+V，1 秒加载。");
        },
      );
    },
    selectLink() {
      const domInputLink = document.getElementById("inputLinkId");
      if (domInputLink != null) {
        // alert("selectLink() is running....");
        domInputLink.select();
      }
    },
    // 切换微信登录
    WeChatFun() {
      this.type = 2
    },
    // 支付宝登录
    alipayFun() {
      this.type = 3
    },
    // 微信去登录
    onUserLogin() {
      console.debug("点击了微信去登录");
    },
    // 支付宝去登录
    onLoginAlipay() {
      console.debug("点击了支付宝去登录");
    },
    // 注册
    onRegisterFun() {
      this.type = 4
    },
    // 忘记密码
    onForgetPass() {
      this.type = 5
    },
    // 注册跳转登录
    jumpLoginFun() {
      this.type = 1
    },
    // 经过全局回调函数再次调用约定函数名的本地函数。
    reactNetJs(netJsObj) {
      var localParams = netJsObj.localObj;
      if (localParams[1] == "reactPlayVideo")
        this.reactPlayVideo(netJsObj, localParams);
      // else if (localParams[1] == "reactDoLogin")
      //   // 登录react
      //   this.reactDoLogin(netJsObj, localParams);
    },
  }
}
</script>

<style lang="scss" scoped>
/*base code*/
.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.animated.infinite {
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

.animated.hinge {
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
}

@keyframes flipInX {
  0% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    -ms-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    -webkit-transition-timing-function: ease-in;
    transition-timing-function: ease-in;
    opacity: 0
  }

  40% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    -ms-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    -webkit-transition-timing-function: ease-in;
    transition-timing-function: ease-in
  }

  60% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    -ms-transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    opacity: 1
  }

  80% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
    -ms-transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -5deg)
  }

  100% {
    -webkit-transform: perspective(400px);
    -ms-transform: perspective(400px);
    transform: perspective(400px)
  }
}

.flipInX {
  -webkit-backface-visibility: visible !important;
  -ms-backface-visibility: visible !important;
  backface-visibility: visible !important;
  -webkit-animation-name: flipInX;
  animation-name: flipInX
}

.home-main {
  width: 100%;
  // height: 75%; 没有效果。
  min-height: 100vh;
  background: linear-gradient(136deg, #FEFEFE 0%, #DDE9FF 100%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .head-box {
    width: 70%;
    display: flex;
    align-items: center;
    // margin-top: 56px;

    .logo {
      align-self: flex-start;
      margin-left: -4px;
      margin-right: 92px;
    }

    .tip-box {
      display: flex;
      flex-direction: column;

      .tip-box_1 {
        display: flex;
        // align-items: center;
        // margin: auto;

        .veryImportant {
          color: #0344ab; // fc7474 409EFF
          font-weight: 560;
        }

        .importantText {
          color: #f88b13;
        }

        .justSoSo {
          color: #666666;
        }

        .bold-slash {
          font-weight: bold;
        }

        .tip {
          // width: 17px;
          // height: 17px;
          width: 12px;
          height: 12px;
          margin-right: 8px;
        }

        span {
          font-size: 10px; // 12px;
          color: #666666;
        }
      }

      .tip-box_2 {
        display: flex;
        // align-items: center;
        // margin: auto;
        margin-top: 3px;

        .veryImportant {
          color: #fc7474;
        }

        .importantText {
          color: #f88b13;
        }

        .justSoSo {
          color: #666666;
        }

        .bold-slash {
          font-weight: bold;
        }

        .tip {
          // width: 17px;
          // height: 17px;
          width: 12px;
          height: 12px;
          margin-right: 8px;
        }

        span {
          font-size: 10px; // 12px;
          color: #666666;
        }
      }
    }

    .edgeOfficial {
      display: flex;
      align-items: center;
      margin-top: 14px;
      margin-bottom: 36px;

      img {
        width: 9px;
        height: 9px;
        margin-right: 8px;
      }

      span {
        font-size: .8rem; // 1rem;
        font-weight: 500;
        color: #FFFFFF;
        cursor: pointer;
      }

      .underline {
        font-size: .84rem; // 1rem;
        font-weight: 600;
        color: #0681fc;
        text-decoration-line: underline;
      }
    }
  }

  .center-box {
    display: flex;
    height: 80%;
    width: 70%;
    min-width: 860px;
    border-radius: 6px; // 15px
    box-shadow: 0px 3px 20px 1px rgba(32, 85, 138, 0.09);
    background: #FFFFFF;
    margin-bottom: 36px; // 75px;

    .left-content {
      width: 60%;
      // min-height: 80%;
      height: 100%;
      background-image: linear-gradient(120deg, #20558A 0%, #1CB0F6 40%, #85D8FF 60%, #FBFBFB 100%);
      border-radius: 6px 0 0 6px; // 15px 0 0 15px

      .content-box {
        height: 100%;
        width: 100%;
        padding-top: 26px; // 60px;
        padding-left: 34px; // 26px; 在左下角增添Windows Edge内容后，视觉拥挤 -- 调整左右侧的面积/位置比例。
        display: flex;
        flex-direction: column;
        background-image: url(../assets/mengban.png);
        background-repeat: no-repeat;

        .title {
          width: 90%; // 60%;
          // font-size: 0.975rem;// 1.3rem;
          // font-size: 1.3rem; // 1.3rem;
          font-size: 22px;
          font-weight: bold;
          color: #FFFFFF;

          .assist {
            // color: #FFFFFF;
            font-size: 20.4px;
            font-weight: 900;
          }

          .parting-line {
            margin-top: 2px;
            height: 2px;
            width: 98%;
            background-color: #FFFFFF;
          }
        }

        .text-1-catch {
          margin-top: 4px;
          margin-bottom: 2px;
          // font-size: 0.8rem; // 1rem;
          font-size: 12.8px; // 1rem;
          font-weight: 600;
          color: #FFFFFF;

          .img-prefix {
            // display: flex;
            align-items: center;
            justify-content: center;
            width: 4px;
            height: 4px;
            margin: 4px 4px 2px 0;
          }

          .minute {
            // font-size: 0.88rem; // 1rem;
            font-size: 13px; // 14.08px;
            // color: #f81616bf; // 引入红色反而效果不好：增大“1”和“5”字体效果更佳。
          }
        }

        .text-candidate {
          margin-top: 2px;
          margin-bottom: 16px;
          // font-size: 0.8rem; // 1rem;
          font-size: 12px; // 1rem;
          font-weight: 600;
          color: #FFFFFF;

          .able-list {
            width: 4px;
            height: 4px;
            margin: 0 4px 2px 0;
          }

          .img-prefix {
            // display: flex;
            align-items: center;
            justify-content: center;
            width: 4px;
            height: 4px;
            margin: 4px 4px 2px 0;
          }
        }

        .text-tip {
          height: 28px;
          width: 246px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 4px;
          color: #FFFFFF;
          font-size: 0.8rem; // 1rem;
          border: 1px solid #FFFFFF;
          margin-bottom: 22px;
        }

        .text-remark {
          height: 28px;
          width: 266px;
          display: flex;
          align-items: center;
          justify-content: center;
          // border-radius: 4px;
          color: #030ad2;
          font-size: 11px; // 0.8rem;
          // border: 1px solid #FFFFFF;
          // margin-bottom: 22px;
          margin: -49px 0 18px 208px;
        }

        .full-text-box {
          margin-bottom: 10px;
          display: flex;
          align-items: center;

          img {
            min-width: 12px;
            min-height: 12px;
            margin-right: 3px;
          }

          span {
            font-size: .375rem; // .5rem;
            color: #FFFFFF;
          }
        }

        .Proper {
          font-size: .9rem; // 1.2rem;
          color: #FFFFFF;
          font-weight: 800;
          margin-top: 24px;
          margin-bottom: 40px;

          .parting-line2 {
            margin-top: 2px;
            height: 2px;
            width: 200px;
            background-color: #FFFFFF;
          }
        }

        .video-box {
          display: flex;
          align-items: center;
          // flex-wrap: wrap;
          width: 100%;

          .video-left {
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            align-items: center;
            // margin-right: 70px;
            min-width: 180px;

            img {
              width: 18rem; // 8rem;
              height: 10rem; // 8rem;
              margin-bottom: 6px; // 19px;
              cursor: pointer;
            }

            span {
              font-size: 0.75rem; // 1rem;
              font-weight: bold;
              color: #FFFFFF;
              cursor: pointer;
            }
          }

          .video-right {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            // margin-top: 10px;

            .video-content {
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              min-width: 160px; // 200px;
              // margin-bottom: 20px;

              img {
                width: 7rem; // 2.2rem;
                height: 10rem; // 2.2rem;
                margin-bottom: 6px; // 10px;
                cursor: pointer;
              }

              span {
                font-size: .375rem; // .5rem;
                color: #FFFFFF;
                cursor: pointer;
              }
            }
          }
        }

        .bottom-box-1 {
          display: flex;
          align-items: center;
          margin-top: 4px;
          // margin-bottom: 0;

          img {
            width: 9px;
            height: 9px;
            margin-right: 8px;
          }

          span {
            // font-size: 0.8rem; // 1rem;
            font-size: 12.8px;
            font-weight: 600;
            color: #FFFFFF;
          }
        }

        .bottom-box-2 {
          display: flex;
          align-items: center;
          margin-top: 38px; //18px
          margin-bottom: 16px;
          font-size: .9rem; // 1rem;
          font-weight: 900;
          color: #FFFFFF;

          .red-pubmed {
            color: rgb(244, 44, 44);
            // color: red;
            font-weight: 600;
            margin-left: 1px;
          }

          // img {
          //   width: 9px;
          //   height: 9px;
          //   margin-right: 8px;
          // }

          .blue-edge {
            color: #0344ab;
            // .blue-edge-light {
            //   color: #0344ab;
            //   font-weight: 500;
            // }
          }

          .copy-link-cursor {
            // font-size: 0.8rem; // 1rem;
            font-size: 12.8px; // 1rem;
            font-weight: 500;
            color: #FFFFFF;
            cursor: pointer;
            margin-top: 2px;

            .red-click {
              color: rgb(244, 44, 44);
              // color: red;
              font-weight: 600;
            }

            .click-hand {
              width: 26px;
              height: 18px;
              margin: 14px 0 -14px -198px;
              position: relative;
              z-index: 999;
            }

          }

          .operate-ext-mesh {
            // font-size: 0.8rem; // 1rem;
            font-size: 12.8px; // 1rem;
            font-weight: 500;
            color: #FFFFFF;
            cursor: text;
            margin-top: 6px;

            .windows-edge {
              font-weight: 600;
            }

            .windows-png {
              width: 18px;
              height: 18px;
              // margin: 0, 4px, 0, 4px;
            }

            .enter-key {
              margin: -2px 0 0 76px;
            }

            .btn-ext-mesh {
              width: 48px;
              height: 20px;
              margin: 6px 0 -4px 6px;
            }

            .ctrl-v {
              font-weight: 860;
            }

            .underline {
              color: #409EFF; // 0681fc
              text-decoration-line: underline;
              font-weight: 560;

              .red-click {
                color: rgb(244, 44, 44);
                // color: red;
                font-weight: 600;
              }
            }

            .red-click {
              color: rgb(244, 44, 44);
              // color: red;
              font-weight: 600;
            }

            .blue-load {
              color: #409EFF; // 0681fc
              font-size: 14px;
              font-weight: 700;
            }
          }


          .copy-link {
            width: 4px;
            height: 22px;
            margin: 4px 4px -6px -8px;
          }

          .div-url-mesh {
            margin-top: 2px;
            z-index: 0;
          }

          .url-mesh {
            // font-size: 0.66rem; // 1rem;
            border: none;
            background-color: #86bbf1; // 86bbf1, d6d5e0
            font-size: 11px; // 1rem;
            font-weight: 540;
            color: #0344ab;
            z-index: 0;
          }

          .window-edge {
            font-size: 11px; // 1rem;
            font-weight: 540;
            color: #FFFFFF;
          }

          .click_2 {
            width: 22px;
            height: 16px;
            margin: 16px 0 -14px -326px;
          }

          .no-pointer {
            // font-size: 0.8rem; // 1rem;
            font-size: 12.8px;
            font-weight: 600;
            color: #FFFFFF;
            cursor: text;
          }
        }

        .more {
          margin: 10px 0 16px 0; // 30px 0;
          font-size: .3px;
          color: #FFFFFF;
          display: flex;
          align-items: center;
          cursor: pointer;

          img {
            margin-left: 6px;
            width: 6px;
            height: 10px;
          }
        }
      }
    }

    .right-content {
      width: 40%; // 必须要有width占比数值：不同于ExtHomeView，主页是left-right左右2部分。
      height: 100%;
      min-width: 344px; // 376px; 在左下角增添Windows Edge内容后，视觉拥挤 -- 调整左右侧的面积/位置比例。
      // background: pink;
      // border-radius: 0px 15px 15px 0px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: auto;

      .user-login {
        width: 92%; // 与registerView.vue -- .content-user中的width: 92%; 保持一致。
        // width: 376px;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .page-bottom-box {
    width: 100%;
    text-align: center;
    margin-top: 16px;
    // margin-bottom: 45px;

    span {
      font-size: 11px;
      color: #999999;
    }
  }

  .dialog-box {
    width: 75%;

    :deep(.el-dialog__header) {
      padding: 0;
      background: #7c7d7f;
    }

    :deep(.el-dialog__body) {
      padding: 0;
      background: #7c7d7f;
    }
  }
}
</style>