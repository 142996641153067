<template>
    <div class="main-login">
        <div class="change-way">
            <div class="way">
                <span class="ok-change name-text">重置密码</span>
                <p class="ok-line line"></p>
            </div>
        </div>
        <div class="content-user">
            <el-form :model="ruleFormReset" :rules="rulesReset" ref="ruleFormReset" class="phone-box">
                <!-- 重置密码用例，用户名不可编辑：1、默认强制用户名存在，才有密码；2、重置密码时不可修改用户名。 -->
                <el-form-item>
                    <el-input class="my-input" disabled placeholder="用户名：重置密码时不可编辑。感谢您的谅解和支持。"></el-input>
                </el-form-item>
                <el-form-item prop="phoneNum">
                    <el-input class="my-input" v-model="ruleFormReset.phoneNum" placeholder="+86 | 手机号"></el-input>
                </el-form-item>
                <el-form-item prop="inputVerifyCode">
                    <el-input class="my-input" v-model="ruleFormReset.inputVerifyCode" placeholder="请输入短信验证码"></el-input>
                    <span :id="ruleFormReset.innerSpanId" @click="reqVerifyCode('ruleFormReset')" class="get-code">{{
                        ruleFormReset.textReq }}</span>
                </el-form-item>
                <el-form-item prop="password">
                    <el-input type="password" class="my-input" v-model="ruleFormReset.password"
                        placeholder="输入新密码"></el-input>
                </el-form-item>
                <el-form-item prop="checkPass">
                    <el-input type="password" class="my-input" v-model="ruleFormReset.checkPass"
                        placeholder="再次输入新密码"></el-input>
                </el-form-item>
                <div class="submit">
                    <el-button type="primary" @click="submitResetPassword('ruleFormReset')">确定</el-button>
                </div>
            </el-form>
            <div @click="goLoginFun" class="go_login">返回登录</div>
            <!-- <div class="about">
                <span @click="localOpenFeedback">用户反馈</span> | <span @click="localOpenAboutUs">关于我们</span>
            </div> -->
        </div>
    </div>
</template>

<script>
import { net } from "@/utils/net.js";
import { urlPrefix, verifyCodeMATCHOK } from "@/utils/common.js";
// import { openAgreement, openPrivacy, openAboutUs, openFeedback } from "@/utils/common.js";

export default {
    name: 'forgot-pass',
    data() {
        let validatePhone = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('请输入手机号'));
            } else {
                let reg = /^(?:(?:\+|00)86)?1(?:(?:3[\d])|(?:4[5-79])|(?:5[0-35-9])|(?:6[5-7])|(?:7[0-8])|(?:8[\d])|(?:9[1589]))\d{8}$/
                if (!reg.test(value)) {
                    callback(new Error('请输入正确的手机号'));
                    return
                }
                callback();
            }
        };
        let validateVerifyCode = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('请输入短信校验码'));
                return;
            } else {
                if (this.cachedLocalVerifyCodesSet.has(value.trim())) {
                    callback(new Error("每条短信校验码只能校验1次：\"" + value + "\" 。感谢您的谅解和支持。"));
                    return;
                }
                let regexVerifyCodeNumber = /^\d{4,6}$/;
                if (!regexVerifyCodeNumber.test(value)) {
                    callback(new Error('请输入正确的短信校验码'));
                    return;
                }
                callback();// callback()的真实功能是，让validate调用链得以持续进行，尤其是，完成后回到caller代码。
            }
        };
        let validatePass = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('请输入密码'));
            } else {
                if (this.ruleFormReset.checkPass !== '') {
                    this.$refs.ruleFormReset.validateField('checkPass');
                }
                callback();
            }
        };
        let validatePass2 = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('请再次输入密码'));
            } else if (value !== this.ruleFormReset.password) {
                callback(new Error('两次输入密码不一致!'));
            } else {
                callback();
            }
        };
        return {
            rulesReset: {
                phoneNum: [
                    { validator: validatePhone, trigger: 'blur' },
                ],
                inputVerifyCode: [
                    { validator: validateVerifyCode, trigger: 'blur' },
                ],
                // userName: [
                //     { validator: validateAccount, trigger: 'blur' },
                // ],
                password: [
                    { validator: validatePass, trigger: 'blur' },
                ],
                checkPass: [
                    { validator: validatePass2, trigger: 'blur' },
                ],
            },
            // 验证码模板类型。
            // 统一的字符串：来自com.medwords.aliyun_sms.AliyunSMSConfiguration，以及HttpRequest。
            SMS_VERIFY_TYPE_REGISTER: "verify_code_regi",// 注册验证码
            SMS_VERIFY_TYPE_LOGIN: "verify_code_login",// 登录验证码
            SMS_VERIFY_TYPE_RESET_PASSWORDS: "verify_code_reset", // 重置密码验证码
            // 结合"获取验证码"不可用时间倒计时长度，对抗NoStrIdSessionStr之前的爬虫攻击的逻辑就很清晰了：	
            // pre_session_str所标记的pre_session_id ###不可能超出### 不可用时间长度来连续请求验证码。比如2条/分钟。
            countDownValue: 60,// "获取验证码"不可用时间倒计时长度。
            ruleFormReset: {
                phoneNum: '',//用户输入的手机号码
                inputVerifyCode: '',// 用户输入的短信验证码
                verifyCodeType: "verify_code_reset",
                textReq: "获取验证码",
                innerSpanId: "ruleFormAccount_span_id",// 设置id来获取<span>并改变其显示的" 59 s 后才能再次获取验证码"。
                localAvailable: true,// VUE JS端能否再次点击<span>请求获取验证码
                // userName: '', //登录名。<!-- 重置密码用例，用户名不可编辑：1、默认强制用户名存在，才有密码；2、重置密码时不可修改用户名。 -->
                password: '',
                checkPass: '',
                countDown: 60,
                intervalHandle: null,
                serverAvailable: false,// 服务器端是否已经完成了verifyCode的存储，两端同步。
            },
            // 将本地已经上行的短信验证码缓存在本地：服务器SMS下发给手机的短信验证码都只能上行检验1次。
            // 所有短信验证码用例，VUE端，都使用统一的js规范：verifyCodeMATCHOK()
            // 结合reset field，维护UI状态。
            cachedLocalVerifyCodesSet: new Set(),
            intervalFormObj: null,// 文字显示倒计时60s：通过本ViewObj this的“全局引用”来取到数据对象Obj。
        }
    },
    methods: {
        reqVerifyCode(formName) {
            // alert('reqVerifyCode(' + formName + ')');
            // validateField()方法能够只检查'phoneNum'，而不是整个form。
            var isPhoneNumOK = true;
            this.$refs[formName].validateField(['phoneNum'], (validationMsg) => {
                // 如果验证手机号码不通过，validateField()返回错误信息字符串，其length > 0；
                // 如果验证手机号码通过了，validateField()返回字符串，其length == 0。
                // alert("validateField(['phoneNum'] 返回 " + validationMsg);
                // alert("validOrErrorMsg.length = " + validationMsg.length);

                isPhoneNumOK = validationMsg == null ? false : validationMsg.length <= 0;
            });
            if (!isPhoneNumOK) {
                // alert("验证手机号码没有通过。isPhoneNumOK = " + isPhoneNumOK);
                return;
            }

            // alert("reqVerifyCode()继续执行！");
            let thisRuleFormObj = null;
            // if (formName == "ruleFormSMS" && this.ruleFormSMS.localAvailable) {
            //     thisRuleFormObj = this.ruleFormSMS;
            // } else 
            if (formName == "ruleFormReset" && this.ruleFormReset.localAvailable) {
                thisRuleFormObj = this.ruleFormReset;
            }
            if (thisRuleFormObj == null) return;// 比如localAvailable = false; 不再能够请求验证码，直至60s之后。

            // 上行参数
            const phoneNumber = thisRuleFormObj.phoneNum;
            const verifyCodeType = thisRuleFormObj.verifyCodeType;
            if (verifyCodeType == null || verifyCodeType.trim == "")
                return;

            // 文字显示倒计时60s：通过本ViewObj this的“全局引用”来取到数据对象Obj。
            this.intervalFormObj = thisRuleFormObj;
            thisRuleFormObj.intervalHandle =
                window.setInterval(this.showCountdownText, 1000);

            var url = urlPrefix + "/reqVerifyCode.mesh";
            var localParams = new Array(this, "reactReqVerifyCode", phoneNumber, verifyCodeType, thisRuleFormObj); //本组件实例this能够传递给回调函数。
            new net.ContentLoader(url, this.reactNetJsCallBack, null, "POST",
                "phoneNumber=" + phoneNumber + "&verifyCodeType=" + verifyCodeType, localParams);

        },
        reactReqVerifyCode(netJsObj, localParams) {
            // 如果要再次提交URL Request，届时要求传入本组件实例registerViewObj（而不是netJsObj实例"this"）。
            const resetViewObj = localParams[0];
            const upPhoneNumber = localParams[2];
            const upVerifyCodeType = localParams[3];
            const ruleFormObj = localParams[4];

            var jsonObj = null;
            try {
                jsonObj = JSON.parse(netJsObj.req.responseText);
            } catch (error) {
                console.error("*** reactReqVerifyCode() -- error from JSON.parse(netJsObj.req.responseText)", error.message);
            }
            // console.debug("JSON.parse(netJsObj.req.responseText)执行完毕！");

            //通用的 - 统一的js规范：在if (jsonObj.ok == 'OK')之后的第一句：（是否）跳转到login page。
            // 注册用例不使用上述统一的js规范。
            // if (switchToLoginPage(jsonObj)) return;

            if (netJsObj.req && jsonObj != null && jsonObj.ok == "OK") {
                const downPhoneNumber = jsonObj.phoneNumber;
                const downVerifyCodeType = jsonObj.verifyCodeType;
                if (upPhoneNumber != downPhoneNumber || upVerifyCodeType != downVerifyCodeType)
                    return;
                // 根据下行信号，在VUE JS端保证形成同步：
                // 确认服务器完成短信验证码存储后，后续VUE JS才再度上行，完成对照处理。
                ruleFormObj.serverAvailable = true;
            }
        },
        // 提交重置密码
        submitResetPassword(formName) {
            var localValidation = true;
            // 整个form的检验：包括各个<el-form-item>，比如手机号码和校验码，账号（是否已经存在），以及密码和重复密码。
            this.$refs[formName].validate((valid) => {
                // 回调函数中的return;并不会让主线程执行return；所以由localValidation赋值带回状态到主线程。
                // if (!valid)
                //     return;
                localValidation = valid;
            });
            if (!localValidation)
                return;

            let thisRuleFormObj = null;
            if (formName == "ruleFormReset" && this.ruleFormReset.serverAvailable) {
                thisRuleFormObj = this.ruleFormReset;
            }
            if (thisRuleFormObj == null) {
                alert("正在等待MeSH Proper服务器端回复该验证码，请稍后再次尝试。给您带来不便，敬请谅解。谢谢！");
                return;
            }
            // 不再能够提交注册，直至下一次短信验证码请求并在服务器端完成存储之后。
            thisRuleFormObj.serverAvailable = false;

            if (formName == "ruleFormReset") {
                // 上行参数
                const phoneNumber = thisRuleFormObj.phoneNum;
                const inputVerifyCode = thisRuleFormObj.inputVerifyCode.trim();
                const verifyCodeType = thisRuleFormObj.verifyCodeType;
                const password = thisRuleFormObj.password;
                const checkPass = thisRuleFormObj.checkPass;
                if (password == null || checkPass == null || password.trim() == "" || password != checkPass) {
                    alert("请检查2次输入的密码。给您带来不便，敬请谅解。谢谢。");
                    return;
                }
                // 提交后，本次短信校验码清零。
                thisRuleFormObj.inputVerifyCode = "";

                var url = urlPrefix + "/doResetPassword.mesh";
                var localParams = new Array(this, "reactDoResetPassword", phoneNumber,
                    verifyCodeType, inputVerifyCode, password, thisRuleFormObj);
                new net.ContentLoader(url, this.reactNetJsCallBack, null, "POST",
                    "phoneNumber=" + phoneNumber + "&verifyCodeType=" + verifyCodeType +
                    "&verifyCode=" + inputVerifyCode + "&password=" + password, localParams);
            }

        },
        reactDoResetPassword(netJsObj, localParams) {
            const resetViewObj = localParams[0];
            const upPhoneNumber = localParams[2];
            const upVerifyCodeType = localParams[3];
            const upVerifyCode = localParams[4];
            const upPass = localParams[5];
            const ruleFormObj = localParams[6];

            var jsonObj = null;
            try {
                jsonObj = JSON.parse(netJsObj.req.responseText);
            } catch (error) {
                console.error("*** reactDoResetPassword() -- error from JSON.parse(netJsObj.req.responseText)", error.message);
            }
            // console.debug("JSON.parse(netJsObj.req.responseText)执行完毕！");

            //通用的 - 统一的js规范：在if (jsonObj.ok == 'OK')之后的第一句：（是否）跳转到login page。
            // 注册用例不使用上述统一的js规范。
            // if (switchToLoginPage(jsonObj)) return;

            // 所有短信验证码用例，VUE端，都使用统一的js规范：verifyCodeMATCHOK()
            if (!verifyCodeMATCHOK(jsonObj, upVerifyCode, this.cachedLocalVerifyCodesSet)) {
                ruleFormObj.inputVerifyCode = "";
                return;
            }

            if (netJsObj.req && jsonObj != null && jsonObj.ok == "OK") {
                // "reset_password_ok" = "TRUE/FALSE";
                if (jsonObj.reset_password_ok != null && jsonObj.reset_password_ok == 'TRUE') {
                    // 重置成功
                    alert("重置密码成功。");
                    // 跳转到login页面
                    this.$emit('jumpToLogin')
                } // 否则重置失败，什么也不做。
            }
        },
        // 经过全局回调函数再次调用约定函数名的本地函数。
        reactNetJs(netJsObj) {
            var localParams = netJsObj.localObj;
            if (localParams[1] == "reactReqVerifyCode")
                // 短信校验码
                this.reactReqVerifyCode(netJsObj, localParams);
            // else if (localParams[1] == "reactReqExistedAccount")
            //     // 查询Account是否存在
            //     this.reactReqExistedAccount(netJsObj, localParams);
            else if (localParams[1] == "reactDoResetPassword")
                // 注册用例
                this.reactDoResetPassword(netJsObj, localParams);
        },
        // 因为是在window.setInterval()中被调用，所以不能带有函数参数：
        // 所需参数（数据对象Obj）只能通过本ViewObj this的“全局引用”来取到。
        showCountdownText() {
            // console.debug("*** showCountdownText()正在执行……");
            // 通过本ViewObj this的“全局引用”来取到数据对象Obj。
            const crtRuleFormObj = this.intervalFormObj;
            //  禁用onclick响应
            crtRuleFormObj.localAvailable = false;// 不再能够请求验证码，直至60s之后。
            document.getElementById(crtRuleFormObj.innerSpanId).style.cursor = "text";

            if (crtRuleFormObj.countDown <= 0 || crtRuleFormObj.countDown > this.countDownValue)
                crtRuleFormObj.countDown = this.countDownValue;// 60

            crtRuleFormObj.countDown--;// 倒计时。
            crtRuleFormObj.textReq = crtRuleFormObj.countDown + " s 后才能再次获取验证码";// 更新倒计时文字。
            if (crtRuleFormObj.countDown == 0) {// 倒计时结束，并恢复localAvailable状态。
                window.clearInterval(crtRuleFormObj.intervalHandle);
                // 恢复localAvailable：恢复onclick响应。
                crtRuleFormObj.localAvailable = true;
                crtRuleFormObj.textReq = "获取验证码";
                document.getElementById(crtRuleFormObj.innerSpanId).style.cursor = "pointer";
            }
        },
        // 去登录
        goLoginFun() {
            this.$emit('jumpToLogin')
        },
        localOpenAgreement() {
            openAgreement();
        },
        localOpenPrivacy() {
            openPrivacy();
        },
        localOpenAboutUs() {
            openAboutUs();
        },
        localOpenFeedback() {
            openFeedback();
        },
    }
}
</script>

<style lang="scss" scoped>
.main-login {
    // margin-top: 10px;
    width: 93%; // 100%;

    .change-way {
        display: flex;
        align-items: center;
        justify-content: center;

        .way {
            width: 33%; // 188px;
            display: flex;
            flex-direction: column;
            align-items: center;
            cursor: pointer;

            .name-text {
                font-size: 1rem;
                font-weight: bold;
            }

            .line {
                width: 100%;
                height: 3px;
                margin-top: 4px;
            }

            .ok-change {
                color: #1CB0F6;
            }

            .no-change {
                color: #666666;
            }

            .ok-line {
                background-color: #1CB0F6;
            }

            .no-line {
                background-color: #FFFFFF;
            }
        }
    }

    .content-user {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-top: 30px;

        .phone-box {
            position: relative;

            .el-form-item {
                height: 36px;
                margin-bottom: 10px; // 错误提示语的空间。
            }

            .el-form-item is-error {
                height: 36px;
                margin-bottom: 10px; // 错误提示语的空间。

            }

            // ::v-deep的作用就是找到当前元素下所有与::v-deep后面class名字匹配的元素，并实现修改样式。
            ::v-deep .el-form-item__content {
                line-height: 26px;
                height: 26px;

            }

            // ::v-deep不需要逐级嵌套。
            ::v-deep .el-form-item__error {
                font-size: 12px; // 错误提示语的字体大小。
                padding-top: 0px; // 错误提示语的padding-top。
            }

            .get-code {
                position: absolute;
                right: 9px;
                bottom: 0px;
                z-index: 99;
                color: #1CB0F6;
                cursor: pointer;
            }

            .forget-pass {
                position: absolute;
                right: 0px;
                top: 110px;
                font-size: .4rem;
                color: #999999;
                cursor: pointer;
            }

            .submit {
                display: flex;
                flex-direction: column;
                margin-top: 36px;

                button {
                    margin-top: 20px;
                }

                :deep(.el-button+.el-button) {
                    margin-left: 0;
                }
            }
        }

        .go_login {
            font-size: 14px; // 1rem;
            font-weight: 600;
            margin-top: 20px;
            color: #1CB0F6;
            cursor: pointer;
        }

        .about {
            text-align: center;
            margin-top: 100px;
            font-size: .4rem;
            font-weight: 500;
            color: #999999;

            // 只让<span>区域呈现指针，否则指针横贯整行。
            span {
                cursor: pointer;
            }
        }

        .my-input {
            width: 100%;

            :deep(.el-input__inner) {
                background-color: #F6F6F6;
                height: 26px; // 输入框的高度：为错误提示语留出空间
                line-height: 26px; // 输入框的高度：为错误提示语留出空间
                padding: 0px 10px;
            }
        }
    }
}
</style>